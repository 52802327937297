const _ = require('lodash')
import moment from "moment"
moment.locale('pt-BR')

export const yesNoOptions = [
	{ id: '1', caption: 'Sim' },
	{ id: '0', caption: 'Não' },
];

export const apiMockedOptions = [
	{ id: '1', caption: 'Opção 1 (api)' },
	{ id: '2', caption: 'Opção 2 (api)' },
];

export const statesBrOptions = [
	{ id: 'AC', caption: 'AC' },
	{ id: 'AL', caption: 'AL' },
	{ id: 'AM', caption: 'AM' },
	{ id: 'AP', caption: 'AP' },
	{ id: 'BA', caption: 'BA' },
	{ id: 'CE', caption: 'CE' },
	{ id: 'DF', caption: 'DF' },
	{ id: 'ES', caption: 'ES' },
	{ id: 'GO', caption: 'GO' },
	{ id: 'MA', caption: 'MA' },
	{ id: 'MG', caption: 'MG' },
	{ id: 'MS', caption: 'MS' },
	{ id: 'MT', caption: 'MT' },
	{ id: 'PA', caption: 'PA' },
	{ id: 'PB', caption: 'PB' },
	{ id: 'PE', caption: 'PE' },
	{ id: 'PI', caption: 'PI' },
	{ id: 'PR', caption: 'PR' },
	{ id: 'RJ', caption: 'RJ' },
	{ id: 'RN', caption: 'RN' },
	{ id: 'RO', caption: 'RO' },
	{ id: 'RR', caption: 'RR' },
	{ id: 'RS', caption: 'RS' },
	{ id: 'SC', caption: 'SC' },
	{ id: 'SE', caption: 'SE' },
	{ id: 'SP', caption: 'SP' },
	{ id: 'TO', caption: 'TO' }
]

export const StatusEnum = {
	ACTIVE: 'active',
	INACTIVE: 'inactive',
}

export const statusOptions = [
	{ id: StatusEnum.ACTIVE, caption: 'Ativo' },
	{ id: StatusEnum.INACTIVE, caption: 'Inativo' },
]

export const statusMapped = _.keyBy(statusOptions, 'id')

export const cookiesConsentStatusEnum = {
	ACCEPTED: 'accepted',
	DENIED: 'denied',
}

export const cookiesConsentStatusOptions = [
	{ id: cookiesConsentStatusEnum.ACCEPTED, caption: 'Aceito' },
	{ id: cookiesConsentStatusEnum.DENIED, caption: 'Negado' },
]

export const cookiesConsentStatusMapped = _.keyBy(cookiesConsentStatusOptions, 'id')

export const cookiesConsentActionEnum = {
	CREATED: 'created',
	UPDATED: 'updated',
	UPDATED_DOCUMENT: 'updatedDocument',
	SET_INFORMATION: 'setInformation',
}

export const cookiesConsentActionOptions = [
	{ id: cookiesConsentActionEnum.CREATED, caption: 'Aceito pela primeira vez' },
	{ id: cookiesConsentActionEnum.UPDATED, caption: 'Atualizado' },
	{ id: cookiesConsentActionEnum.UPDATED_DOCUMENT, caption: 'Documento associado' },
	{ id: cookiesConsentActionEnum.SET_INFORMATION, caption: 'Atualizou informações' },
]

export const cookiesConsentActionMapped = _.keyBy(cookiesConsentActionOptions, 'id')

export const datePeriodOptions = [
	{ id: 'today', caption: 'Hoje', value:[
			moment().format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
		]},
	{ id: 'yesterday', caption: 'Ontem', value:[
			//moment().startOf('month').format('YYYY-MM-DD'),
			moment().subtract(1, 'days').format('YYYY-MM-DD'),
			moment().subtract(1, 'days').format('YYYY-MM-DD'),
		]},
	{ id: 'thisWeek', caption: 'Semana Atual', value:[
			moment().startOf('week').format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
		]},
	{ id: 'thisMonth', caption: 'Mês Atual', value:[
			moment().startOf('month').format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
		]},
	{ id: 'thisYear', caption: 'Desde o início do ano', value:[
			moment().startOf('year').format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
		]},
]

export const datePeriodMapped = _.keyBy(datePeriodOptions, 'id')

