import React, {useState, useEffect, useMemo} from 'react';

import api, { sleep } from "../../../helpers/api/api"

import {useOvermind, useOvermindForm} from "../../../overmind";
import {Datatable} from "../../../components/Common/Tables/Datatable";
import CustomHeader from "../../../components/Common/Tables/Datatable/CustomHeader";

import CustomForm from "../../../components/Common/CustomForm/Form";
import DefaultPage from "../../../components/Common/DefaultPage/DefaultPage";

import FormWizard from "../../../components/Common/CustomForm/FormWizard";

import ChannelsView from "../View";

import PageDatatable from "../../../components/Common/Tables/Datatable/PageDatatable";
import DefaultPageHeader from "../../../components/Common/DefaultPage/DefaultPageHeader";
import {downloadFileClient} from "../../../helpers/url";
import {DataActionButton} from "../../../components/Common/Tables/Datatable/DataTypes";
import UserCanAll from "../../../components/Common/UserCan/UserCanAll";
import {UserCan} from "../../../helpers/api/auth";
import {useLocalStorage} from "../../../helpers/api/UseLocalStorage";
import ButtonPrimary from "../../../components/Common/Button/ButtonPrimary";
import {statusMapped, statusOptions} from "../../../constants/formLists";

const mainTabs = [
	{id:'all', caption:"Busca"},
]

const apiEndpoint = {
	path : `channels/search`,
	method : `post`,
}


export const ChannelsSearch = () => {
	const formId = 'ChannelsSearch'

	const [searchParams, setSearchParams] = useState(null);
	const [filterParams, setFilterParams] = useState({});
	// const [filterStatus, setFilterStatus] = useState(history.location?.state?.status || 'all');
	// const [selectedPeriod, setSelectedPeriod] = useState(null)
	const [selectedIds, setSelectedIds] = useState([])
	const [compactMode, setCompactMode] = useLocalStorage(`compact-mode-${formId}`, true)
	const [exportingResult, setExportingResult] = useState(false)
	const [viewData, setViewData] = useState(null)

	const form = useOvermindForm(formId)

	const channels = useOvermind('channels')

	const handleExportResult = (evt, fileTitle=null) => {

		setExportingResult(true)
		api
			.post(`channels/downloadCsv`, searchParams)
			.then(res => downloadFileClient(res, fileTitle))
			.catch(e => alert(`Ocorreu um erro ao fazer o download: ${e.toString()}`))
			.finally(() => setExportingResult(false))
	}

	const refreshList = () => {
		setSearchParams({
			...filterParams,
			// periodStart : model?.period?.length && model?.period[0],
			// periodEnd : model?.period?.length && model?.period[1],
		})
	}

	useEffect(() => {
		refreshList()
	}, [filterParams])

	const handleFilterChange = () => {
		const newParams = {
			...filterParams,
			..._.cloneDeep(form.state.model),
			//updatedAt : form.state.model.updatedAt ? dateBrToISO(form.state.model.updatedAt) : null,
			//value : parseMoney(form.state.model.value) ,
		}
		console.log('handleFilterChange', newParams)
		setFilterParams(newParams)
	}

	const handleClick = row => {
		channels.actions.loadById(row.id)
			.then(record => setViewData(record))
			.catch(e => alert(`Ocorreu um erro ao carregar os dados`))
	};

	const handleNew = async () => {
		setViewData({})
	};

	const handleDelete = async (row) => {
		if (confirm(`Deletar registro "${row.name}" ?`)) {
			channels
				.actions
				.deleteById(row.id)
				.then(() => {
					refreshList()
				})
				.catch((e) => {
					const errCode = e.response?.data?.error?.message
					if (errCode==='being_used') {
						alert(`\nNão foi possível remover este registro, ele está sendo utilizado.`)
					} else {
						alert('Ocorreu um erro ao tentar deletar este registro')
					}
				})
		}
	};

	const columns = useMemo(() => [
		// {
		// 	id : 'id',
		// 	name: <CustomHeader onComplete={handleFilterChange} id={'id'} formId={formId} fieldProps={{type:'number'}}>ID</CustomHeader>,
		// 	grow:0,
		// 	width:'64px',
		// 	selector: row => row?.id,
		// 	sortable: true,
		// 	compact : true,
		// 	wrap : true,
		//
		// },
		{
			id : 'name',
			name: <CustomHeader onComplete={handleFilterChange}  id={'name'} formId={formId} fieldProps={{type:'text'}}>Nome do Canal</CustomHeader>,
			grow:1,
			minWidth:'128px',
			selector: row => row?.name,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'domain',
			name: <CustomHeader onComplete={handleFilterChange}  id={'domain'} formId={formId} fieldProps={{type:'text'}}>Domínio</CustomHeader>,
			grow:1,
			minWidth:'128px',
			selector: row => row?.domain,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'status',
			name: <CustomHeader onComplete={handleFilterChange}  id={'status'} formId={formId} fieldProps={{type:'select2', options:statusOptions}}>Status</CustomHeader>,
			grow:1,
			minWidth:'128px',
			selector: row => statusMapped[row?.status]?.caption || row?.status,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'token',
			name: <CustomHeader onComplete={handleFilterChange}  id={'token'} formId={formId} fieldProps={{type:'text'}}>Token</CustomHeader>,
			grow:1,
			minWidth:'128px',
			selector: row => row?.token,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'actions',
			name: "Ações",
			grow:0,
			minWidth:'72px',
			center:true,
			omit : !UserCan('channels.edit'),
			selector: function A(row) {
				return <>
					<DataActionButton
						disabled={row.id==1}
						buttonClass="btn btn-outline-danger m-1 btn-sm"
						iconClass="fas fa-trash m-1"
						onClick={() => handleDelete(row)}
					/>
				</>
			},
			sortable: false,
			compact : true,
			wrap : true,
		},
	], [compactMode]);

	const searchFields = useMemo(() => [
	], []);

	return (<UserCanAll permission="channels.consult">
		<ChannelsView
			viewData={viewData}
			onConfirm={() => {
				setViewData(null)
			   	refreshList()
		   	}}
			onCancel={() => setViewData(null)}
		/>
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title="Canais Fiduc"  />
			}
		>
			<CustomForm
				className="needs-validation"
				formId={formId}
				model={form.state.model}
				validateOnLoad={false}
			>

				<PageDatatable
					formId={formId}
					tabs={mainTabs}
					activeTab={'all'}
					onTabChange={null}
					TabActions={<>
						<UserCanAll permission="channels.edit">
						<ButtonPrimary
							className="m-1"
							small={true}
							onClick={handleNew}
							caption="Adicionar Canal"
							icon="fas fa-plus"
						/>
						</UserCanAll>

						<ButtonPrimary
							loading={exportingResult}
							className="m-1"
							outlined={true}
							onClick={handleExportResult}
							caption="Exportar"
							icon="fa fa-file-excel"
						/>
						{/*<Button*/}
						{/*	disabled={false}*/}
						{/*	colorIcon={true}*/}
						{/*	className="btn btn-outline-primary m-1 btn-sm"*/}
						{/*	// disabled={currentScreen==0}*/}
						{/*	onClick={() => setCompactMode(!compactMode)}>*/}
						{/*	<i className={`m-1 fa ${compactMode ? "fa-eye-slash" : "fa-eye"} align-middle`}></i>*/}
						{/*</Button>*/}
					</>}
					HeadContainer={
						<FormWizard
							screenId={formId}
							screens={searchFields}
							formId={formId}
							// OnInputValueChange={handleInputValueChange}
							tabId={formId}
						/>
					}
				>
					<Datatable
						heightSpace={'220px'}
						selectableRows={false}
						onChangeSelectedRows={(selectedRows) => {
							setSelectedIds(_.map(Object.values(selectedRows), 'id'))
						}}
						onRowClicked={(row, e) => handleClick(row)}
						keyField={formId}
						formId={formId}
						columns={columns}
						searchParams={searchParams}
						apiEndpoint={apiEndpoint}
					/>
				</PageDatatable>

			</CustomForm>
		</DefaultPage>
		</UserCanAll>
	);
};

export default ChannelsSearch;
