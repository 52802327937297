import React, {useState, useEffect, useMemo} from 'react';

import {Card, CardBody, Col, Container, Row, Spinner, Badge} from "reactstrap";

import {Datatable} from "../../../../components/Common/Tables/Datatable";
import moment from "moment"
import TabDefault from "../../../../components/Common/Tabs/TabDefault";
moment.locale('pt-BR')

export const PageDatatable = (
	{
		children,
		formId,
		tabs = null,
		activeTab=null,
		onTabChange = null,
		HeadContainer = null,
		TabActions = null,

		...props
	}
) => {

	return <>
		<Container fluid className="">
			<Row className={"mb-0 "}>
				<Col md={12} className=" p-0 m-0">
					{tabs &&
					<TabDefault
						tabs={tabs}
						activeTab={activeTab}
						onChange={onTabChange}
						TabActions={TabActions}
					/>}
				</Col>
			</Row>
			<Row className="search-page-container">
				<Col md={12}>
					<Row className={"xborder border-dark m-0 mt-4 "}>
						{HeadContainer}
					</Row>
					<div className='pt-0 datatable-bottom-float ms-0' style={{}}>
						{children}
					</div>
				</Col>
			</Row>
		</Container>
	</>
}

export default PageDatatable;
