import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
// import {AuthInfo, UserCan} from "../../helpers/api/auth";
import UserCanAny from "../Common/UserCan/UserCanAny";
import UserCanAll from "../Common/UserCan/UserCanAll";

//i18n
//import { withTranslation } from "react-i18next"

const SidebarContent = props => {
	// const authInfo = AuthInfo()

	const ref = useRef();
	// Use ComponentDidMount and ComponentDidUpdate method symultaniously
	useEffect(() => {
		const pathName = props.location.pathname

		const initMenu = () => {
			new MetisMenu("#side-menu")
			let matchingMenuItem = null
			const ul = document.getElementById("side-menu")
			const items = ul.getElementsByTagName("a")
			for (let i = 0; i < items.length; ++i) {
				if (pathName === items[i].pathname) {
					matchingMenuItem = items[i]
					break
				}
			}
			if (matchingMenuItem) {
				activateParentDropdown(matchingMenuItem)
			}
		}
		initMenu()
	}, [props.location.pathname])

	// useEffect(() => {
	// 	ref.current.recalculate()
	// }, [])

	function scrollElement(item) {
		if (item) {
			const currentPosition = item.offsetTop
			if (currentPosition > window.innerHeight) {
				ref.current.getScrollElement().scrollTop = currentPosition - 300
			}
		}
	}

	function activateParentDropdown(item) {
		item.classList.add("active")
		const parent = item.parentElement
		const parent2El = parent.childNodes[1]
		if (parent2El && parent2El.id !== "side-menu") {
			parent2El.classList.add("mm-show")
		}

		if (parent) {
			parent.classList.add("mm-active")
			const parent2 = parent.parentElement

			if (parent2) {
				parent2.classList.add("mm-show") // ul tag

				const parent3 = parent2.parentElement // li tag

				if (parent3) {
					parent3.classList.add("mm-active") // li
					parent3.childNodes[0].classList.add("mm-active") //a
					const parent4 = parent3.parentElement // ul
					if (parent4) {
						parent4.classList.add("mm-show") // ul
						const parent5 = parent4.parentElement
						if (parent5) {
							parent5.classList.add("mm-show") // li
							parent5.childNodes[0].classList.add("mm-active") // a tag
						}
					}
				}
			}
			scrollElement(item);
			return false
		}
		scrollElement(item);
		return false
	}

	return (
		<React.Fragment>
			<SimpleBar className="h-100" ref={ref}>
				<div id="sidebar-menu">
					<ul className="metismenu list-unstyled debug4" id="side-menu">

						<li>
							<Link to={`/testPlugins`} className=" ">
								<i className="fas fa-plug"></i>
								<span>Test Plugins</span>
							</Link>
						</li>

						<UserCanAll permission={['dashboard.consult']}>
						<li>
							<Link to={`/dashboard`} className=" ">
								<i className="fas fa-home"></i>
								<span>Dashboard</span>
							</Link>
						</li>
						</UserCanAll>

						<UserCanAny permission={['cookies.consult', 'cookies.edit']}>
						<li ><a className="has-arrow waves-effect" href="/" aria-expanded="true">
							<i className="mdi mdi-security font-size-14" /><span>Gestão consentimento</span></a>
							<ul className="sub-menu mm-collapse" aria-expanded="false" >

								<UserCanAll permission={['cookies.consult']}>
								<li>
									<Link to={`/cookies/search/restricted`} >
										<i className="mdi mdi-cookie font-size-14 small "/>
										<span className="font-size-em-1">Área restrita</span>
									</Link>
								</li>
								<li>
									<Link to={`/cookies/search/public`} >
										<i className="mdi mdi-cookie font-size-14 small "/>
										<span className="font-size-em-1">Área pública</span>
									</Link>
								</li>
								</UserCanAll>

							</ul>
						</li>

						</UserCanAny>


						<UserCanAny permission={['users.consult', 'profiles.consult']}>
						{/*<li className="menu-title">Outras opções</li>*/}
						<li ><a className="has-arrow waves-effect" href="/" aria-expanded="true">
							<i className="fas fa-users font-size-14" /><span>Usuários</span></a>
							<ul className="sub-menu mm-collapse" aria-expanded="false" >

								<UserCanAll permission={['users.consult']}>
								<li>
									<Link to={`/users/search#${Math.random()}`} className=" ">
										<i className="fas fa-user font-size-14 small "/>
										<span className="font-size-em-1">Usuários</span>
									</Link>
								</li>
								</UserCanAll>

								<UserCanAll permission={['profiles.consult']}>
								<li>
									<Link to={`/profiles/search#${Math.random()}`} className=" ">
										<i className="fas fa-code-branch font-size-14 small "/>
										<span className="font-size-em-1">Perfis</span>
									</Link>
								</li>
								</UserCanAll>
							</ul>
						</li>
						</UserCanAny>

						<UserCanAny permission={['channels.consult']}>
						{/*<li className="menu-title">Outras opções</li>*/}
						<li ><a className="has-arrow waves-effect" href="/" aria-expanded="true">
							<i className="fas fa-users font-size-14" /><span>Configurações</span></a>
							<ul className="sub-menu mm-collapse" aria-expanded="false" >

								<UserCanAll permission={['channels.consult']}>
								<li>
									<Link to={`/channels/search#${Math.random()}`} className=" ">
										<i className="fas fa-user font-size-14 small "/>
										<span className="font-size-em-1">Canais Fiduc</span>
									</Link>
								</li>
								</UserCanAll>

							</ul>
						</li>
						</UserCanAny>


					</ul>
				</div>
			</SimpleBar>
		</React.Fragment>
	)
}

SidebarContent.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
}

export default withRouter(SidebarContent)
