import React, {useState, useEffect, useMemo} from 'react';

import api, { sleep } from "../../../helpers/api/api"
import { useParams } from "react-router-dom"

import {useOvermind, useOvermindForm} from "../../../overmind";
import {Datatable} from "../../../components/Common/Tables/Datatable";
import CustomHeader from "../../../components/Common/Tables/Datatable/CustomHeader";

import CustomForm from "../../../components/Common/CustomForm/Form";
import DefaultPage from "../../../components/Common/DefaultPage/DefaultPage";

import FormWizard from "../../../components/Common/CustomForm/FormWizard";

import CookiesView from "../View";

import PageDatatable from "../../../components/Common/Tables/Datatable/PageDatatable";
import DefaultPageHeader from "../../../components/Common/DefaultPage/DefaultPageHeader";
import {downloadFileClient} from "../../../helpers/url";
import {DataDate} from "../../../components/Common/Tables/Datatable/DataTypes";
import UserCanAll from "../../../components/Common/UserCan/UserCanAll";
import {useLocalStorage} from "../../../helpers/api/UseLocalStorage";
import ButtonPrimary from "../../../components/Common/Button/ButtonPrimary";
import {cookiesConsentStatusMapped, cookiesConsentStatusOptions} from "../../../constants/formLists";
import {dateIsoToBr, formatDocumentOrEmail, onlyNumbers} from "../../../helpers/utils";
import {formatBytes} from "../../../components/Common/CustomForm/Inputs/Upload";

const mainTabs = [
	{id:'all', caption:"Busca"},
]

const apiEndpoint = {
	path : `cookies/search`,
	method : `post`,
}


export const CookiesSearch = ({searchType, ...props}) => {
	const formId = 'CookiesSearch'

	const [loadingRecord, setLoadingRecord] = useState(false);
	const [searchParams, setSearchParams] = useState(null);
	const [filterParams, setFilterParams] = useState({});
	// const [filterStatus, setFilterStatus] = useState(history.location?.state?.status || 'all');
	// const [selectedPeriod, setSelectedPeriod] = useState(null)
	const [selectedIds, setSelectedIds] = useState([])
	const [compactMode, setCompactMode] = useLocalStorage(`compact-mode-${formId}`, true)
	const [exportingResult, setExportingResult] = useState(false)
	const [viewData, setViewData] = useState(null)

	// const {searchType} = useParams()

	const form = useOvermindForm(formId)

	const cookies = useOvermind('cookies')

	const handleExportResult = (evt, fileTitle=null) => {

		setExportingResult(true)
		api
			.post(`cookies/downloadCsv`, searchParams)
			.then(res => downloadFileClient(res, fileTitle))
			.catch(e => alert(`Ocorreu um erro ao fazer o download: ${e.toString()}`))
			.finally(() => setExportingResult(false))
	}

	const refreshList = () => {
		setSearchParams({
			searchType,
			...filterParams,
			// periodStart : model?.period?.length && model?.period[0],
			// periodEnd : model?.period?.length && model?.period[1],
		})
	}

	useEffect(() => {
		refreshList()
	}, [filterParams])

	const handleFilterChange = () => {
		const newParams = {
			...filterParams,
			..._.cloneDeep(form.state.model),
			document : onlyNumbers(form.state.model.document),
			//updatedAt : form.state.model.updatedAt ? dateBrToISO(form.state.model.updatedAt) : null,
			//value : parseMoney(form.state.model.value) ,
		}
		console.log('handleFilterChange', newParams)
		setFilterParams(newParams)
	}

	const handleClick = row => {
		setLoadingRecord(true)
		cookies.actions
			.loadById(row.id)
			.then(record => {
				form.actions.resetUploads({formId:'ModalForm'})
				if (record.attachment) {
					form.actions.setUploadedFiles({
						formId:'ModalForm',
						fieldName:'attachment',
						fileResult:{
							canDownload : true,
							preview : record.attachment,
							formattedSize: formatBytes(record.attachment?.length),
						}
					})
				}
				return record
			})
			.then(record => setViewData({
				...record,
				createdAt:dateIsoToBr(record.createdAt, true)
			}))
			.catch(e => alert(`Ocorreu um erro ao carregar os dados`))
			.finally(() => setLoadingRecord(false))
	};

	const handleDelete = async (row) => {
		if (confirm(`Deletar registro "${row.name}" ?`)) {
			cookies
				.actions
				.deleteById(row.id)
				.then(() => {
					refreshList()
				})
				.catch((e) => {
					const errCode = e.response?.data?.error?.message
					if (errCode==='being_used') {
						alert(`\nNão foi possível remover este registro, ele está sendo utilizado.`)
					} else {
						alert('Ocorreu um erro ao tentar deletar este registro')
					}
				})
		}
	};

	const columns = useMemo(() => {

		const extraFields = searchType==='restricted'
			? [
				{
					id : 'document',
					name: <CustomHeader onComplete={handleFilterChange}  id={'document'} formId={formId} fieldProps={{type:'cpfcnpj'}}>Documento</CustomHeader>,
					grow:1,
					minWidth:'128px',
					selector: row => row?.document ? formatDocumentOrEmail(row?.document) : `(não logado)`,
					sortable: true,
					compact : true,
					wrap : true,
				},
			]
			: [
				{
					id : 'publicDocument',
					name: <CustomHeader onComplete={handleFilterChange}  id={'publicDocument'} formId={formId} fieldProps={{type:'cpfcnpj'}}>Documento Público</CustomHeader>,
					grow:1,
					minWidth:'128px',
					selector: row => row?.publicDocument ? formatDocumentOrEmail(row?.publicDocument) : `(não logado)`,
					sortable: true,
					compact : true,
					wrap : true,
				},
			]

		return [
			...extraFields,
			{
				id : 'channelId',
				name: <CustomHeader onComplete={handleFilterChange}  id={'channelId'} formId={formId} fieldProps={{type:'text'}}>Canal</CustomHeader>,
				grow:1,
				minWidth:'128px',
				selector: row => row?.channel?.name,
				sortable: true,
				compact : true,
				wrap : true,
			},
			{
				id : 'status',
				name: <CustomHeader onComplete={handleFilterChange}  id={'status'} formId={formId} fieldProps={{type:'select2', options:cookiesConsentStatusOptions}}>Status</CustomHeader>,
				grow:0,
				minWidth:'128px',
				selector: row => cookiesConsentStatusMapped[row?.status]?.caption || row?.status,
				sortable: true,
				compact : true,
				wrap : true,
			},
			{
				id : 'remoteAddress',
				name: <CustomHeader onComplete={handleFilterChange}  id={'remoteAddress'} formId={formId} fieldProps={{type:'text'}}>IP</CustomHeader>,
				grow:0,
				minWidth:'128px',
				selector: row => row?.remoteAddress,
				sortable: true,
				compact : true,
				omit:compactMode,
				wrap : true,
			},
			{
				id : 'createdAt',
				name: "Criado em",
				grow:0,
				minWidth:'128px',
				selector: function A(row) {
					return <DataDate value={row?.createdAt}/>
				},
				sortable: true,
				compact : true,
				wrap : true,
			},
			{
				id : 'updatedAt',
				name: "Alterado em",
				grow:0,
				minWidth:'128px',
				selector: function A(row) {
					return <DataDate value={row?.updatedAt}/>
				},
				sortable: true,
				compact : true,
				omit:compactMode,
				wrap : true,
			},
	]
	}, [compactMode, searchType]);

	const searchFields = useMemo(() => [
	], []);

	return (<UserCanAll permission="cookies.consult">
		<CookiesView
			viewData={viewData}
			loading={loadingRecord}
			searchType={searchType}
			onConfirm={() => {
				setViewData(null)
			   	refreshList()
		   	}}
			onCancel={() => setViewData(null)}
		/>
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title={searchType==='restricted' ? `Área restrita` : `Área pública`}  />
			}
		>
			<CustomForm
				className="needs-validation"
				formId={formId}
				model={form.state.model}
				validateOnLoad={false}
			>

				<PageDatatable
					formId={formId}
					tabs={mainTabs}
					activeTab={'all'}
					onTabChange={null}
					TabActions={<>
						<UserCanAll permission="cookies.consult">
						<ButtonPrimary
							loading={exportingResult}
							className="m-1"
							outlined={true}
							onClick={handleExportResult}
							caption="Exportar"
							icon="fa fa-file-excel"
						/>
						</UserCanAll>

						<ButtonPrimary
							disabled={false}
							outlined={true}
							icon={`fa ${compactMode ? "fa-eye-slash" : "fa-eye"}`}
							className="m-1"
							onClick={() => setCompactMode(!compactMode)}
						/>
					</>}
					HeadContainer={
						<FormWizard
							screenId={formId}
							screens={searchFields}
							formId={formId}
							// OnInputValueChange={handleInputValueChange}
							tabId={formId}
						/>
					}
				>
					<Datatable
						heightSpace={'220px'}
						selectableRows={false}
						onChangeSelectedRows={(selectedRows) => {
							setSelectedIds(_.map(Object.values(selectedRows), 'id'))
						}}
						onRowClicked={(row, e) => handleClick(row)}
						keyField={formId}
						formId={formId}
						columns={columns}
						searchParams={searchParams}
						apiEndpoint={apiEndpoint}
					/>
				</PageDatatable>

			</CustomForm>
		</DefaultPage>
		</UserCanAll>
	);
};

export default CookiesSearch;
