import React from "react"
import {UserCan} from "../../../helpers/api/auth";

function UserCanAny({permission, children, ...props} ) {
    return UserCan(permission, true)
		? children
		: null
}

export default UserCanAny
