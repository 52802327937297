import React, {useEffect, useMemo, useState} from "react"
import {Col, Container, Row} from "reactstrap";

import TabDefault from "../../components/Common/Tabs/TabDefault";
import DefaultPage from "../../components/Common/DefaultPage/DefaultPage";
import DefaultPageHeader from "../../components/Common/DefaultPage/DefaultPageHeader";

import TestPluginCookiesVisualFs from "./CookiesVisualFs";
import TestPluginCookiesVisual from "./CookiesVisual";
import TestPluginCookiesStandalone from "./CookiesStandAlone";

function TestPluginPage(props) {

	const tabs = useMemo(() => [
		{id:'cookie', caption:"Cookies", content:TestPluginCookiesStandalone},
		{id:'cookiesVisual', caption:"Cookies Visual", content:TestPluginCookiesVisual},
		{id:'cookiesVisualFs', caption:"Cookies Visual FS", content:TestPluginCookiesVisualFs},
	], [])


	const [activeTab, setActiveTab] = useState(null)

    return (
    	<DefaultPage
			headerComponent={
				<DefaultPageHeader title="Plugins"  />
			}
		>
			<Container fluid className="">
				<Row className={"mb-0 "}>
					<Col md={12} className=" p-0 m-0">
						<TabDefault
							tabs={tabs}
							activeTab={activeTab}
							onChange={tabId => setActiveTab(tabId)}
							hasContent={true}
							containerClassName={'py-1'}
							// TabActions={TabActions}
						/>
					</Col>
				</Row>
			</Container>
		</DefaultPage>
	)
}

export default TestPluginPage;
