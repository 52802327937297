import React from "react"

import FieldMoney from "./Money";

function FieldPercent(props) {
    return <FieldMoney
		maskOptions={{
			prefix:'',
			suffix:'%',
			decimalSymbol:',',
			includeThousandsSeparator:false,
			integerLimit:2,
			decimalLimit:2,
			...props.maskOptions
		}}
		{...props}
	/>
}

export default FieldPercent
