import React, {useEffect, useState} from "react"

import { Row, Col } from "reactstrap"
import { Link } from "react-router-dom"

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import logo from "../../assets/images/logos/logo-light.svg"
import iconLightSvg from "../../assets/images/logos/icon-light.svg"

import lStorage from "../../helpers/api/lStorage";

const Header = (props) => {

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
	const type = document.getElementsByTagName("body")[0].className.match(/sidebar-enable/)

	lStorage.set('sidebarType', type ? 'condensed' : 'default')
  }

  useEffect(() => {
    var body = document.body;
	const type = lStorage.get('sidebarType')
    if (type=='condensed') {
		if (window.screen.width <= 998) {
		  body.classList.add("sidebar-enable");
		} else {
		  body.classList.add("vertical-collpsed");
		  body.classList.add("sidebar-enable");
		}
	}
	// const type = document.getElementsByTagName("body")[0].className.match(/sidebar-enable/)
  }, [])

  return (
    <React.Fragment>
      <header id="page-topbar" className="bg-fiduc-background" >
        <div className="navbar-header">
          <div className="d-flex w-100" >

            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm d-flex justify-content-center align-items-center pt-3">
                  <img src={logo} alt="" height="32" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm d-flex justify-content-center align-items-center pt-3">
                  <img src={iconLightSvg} alt="" height="36" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-em4 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
			  <Col >
				  {typeof(props.headerComponent)=='object' ? props.headerComponent : <props.headerComponent /> }
			  </Col>

          </div>

          <div className="d-flex">


            <div className="dropdown d-none d-sm-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <ProfileMenu />

          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header
