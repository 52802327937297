import React, {useCallback, useEffect, useState} from "react"

// import "../../assets/scss/theme.scss"
// import "../../assets/scss/fiduc/fiduc.scss"

import "../../assets/scss/fiduc/plugins/cookies-visual-fs.scss"
import Cookies from "plugins/cookies"
import LoadingDots from "../../components/Common/LoadingDots/LoadingDots";

const Button = (
	{
		color = "primary",
		caption,
		onClick,
		loading,
		disabled,
	}
) => {
	disabled = loading || disabled

	return (
		<div
			className={`button ${color} ${disabled ? "disabled" : ""}`}
			onClick={e => {
				onClick && onClick();
				e.preventDefault()
			}}
		>
			{caption}
		</div>
	)

}

const CookiesVisualFs = (
	{
		pluginParams,
		...props
	}
) => {

	const [checked, setChecked] = useState(false)
	const [saving, setSaving] = useState(false)

	const show = useCallback(() => {
		const container = document.getElementById('policy-container')
		container.classList.remove('hidden')
		container.classList.add('visible')
		setChecked(false)
	}, [])

	const hide = useCallback(() => {
		const container = document.getElementById('policy-container')
		container.classList.remove('visible')
		container.classList.add('hidden')
	}, [])

	useEffect(() => {
		if (Cookies)
			Cookies.addConfig(pluginParams)

		console.log({pluginParams})

		const container = document.getElementById('policy-container')?.parentElement;
		if (container)
			(document.getElementById('root') || document.body).appendChild(container)

		Cookies.on('onShowConsent', show)
		Cookies.fireEvent('cookies.onLoad')

		return () => {
			Cookies.off('onShowConsent', show)
			try {
				document.getElementById('root').removeChild(container)
			} catch(e) {}
		}

	}, [])

	const handleAccept = async () => {
		if (!checked) {
			alert(`Para continuar é necessário aceitar nossa "Política de Privacidade e Utilização de dados", clique no checkbox de aceite para continuar.`)
			return
		}

		setSaving(true)
		Cookies
			.setConsentStatus('accepted')
			.then(hide)
			.then(() => Cookies.fireEvent('onConsentAccepted'))
			.finally(() => setSaving(false))
	}

	const handleDeny = async () => {

		if (pluginParams.strict) {
			if (confirm(`Para continuar acessando nosso aplicativo é necessário aceitar nossa "Política de Privacidade e Utilização de dados".` )) {
				setSaving(true)
				Cookies
					.setConsentStatus('denied')
					.then(() => Cookies.fireEvent('onConsentDenied'))
					.finally(() => setSaving(false))
			}
		} else {
			if (confirm(`Não aceitar nossa "Política de Privacidade e Utilização de dados" resultará na impossibilidade de utilizar nossos aplicativos, tem certeza que deseja bloquear a utilização de cookies?`)) {
				setSaving(true)
				Cookies
					.setConsentStatus('denied')
					.then(hide)
					.then(() => Cookies.fireEvent('onConsentDenied'))
					.finally(() => setSaving(false))
			}
		}

	}

	return (
		<div id='policy-container'
			 className="policy-container hidden">
			<div className="policy-content">
				<div className="policy-title">
					Lei de Proteção de Dados
				</div>
				<div className="policy-message-container">
					<div className="policy-checkbox-container">
						<input
							checked={checked}
							type="checkbox"
							onChange={e => setChecked(e.target.checked)}
						/>
					</div>
					<div className="policy-message">
						Concordo com o uso de cookies para garantir uma melhor experiência no aplicativo e autorizo a coleta das minhas informações.
						<br/>
						<br/>Para entender como tais dados são tratados, acesse a{" "}
						<a className="policy-link"
						   href="https://fiduc.com.br/wp-content/uploads/2022/09/fiduc-fgf-2022-08-politica-de-privacidade.pdf"
						   target="_blank" rel="noreferrer">Política de Privacidade e Utilização de dados</a>.
					</div>
				</div>
				<div className="policy-loading-dots-container">
				<LoadingDots visible={saving} />
				</div>
				<div className="policy-buttons">
					{!pluginParams.strict &&
					<Button
						caption="Sair"
						color="secondary"
						onClick={handleDeny}
						disabled={saving}
					/>}
					<Button
						loading={saving}
						caption="Prosseguir"
						// color={checked ? "primary" : "secondary"}
						onClick={handleAccept}
					/>
				</div>
			</div>
		</div>
	)
}

export default CookiesVisualFs
