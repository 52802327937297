import React, {useEffect} from "react"

import {RegisterPlugin} from "../../components/Plugins/FiducPlugin";
import FiducPlugin from "../../components/Plugins/FiducPlugin"
import apiPlugins from "../../helpers/api/apiPlugins";

class Cookies extends FiducPlugin {

	constructor(pluginName) {
		super(pluginName)
		// alert(this.params.token)
	}

	async getConsentStatus(document=null) {

		return apiPlugins
			.post(`lgpd/cookies/getConsentStatus`,
				{
					UID  : this.getGuestUniqueID(),
					token  : this.config.token,
					domain : window.location.hostname,
					document : document || this.config.userDocument,
				}
			)
			.then(res => res.data)
			.then(consent => {
				if (consent?.accepted)
					this.fireEvent('onCookiesConsentAccepted', null, true)
				return consent
			})
	}

	async setConsentStatus(status, document=null) {
		return apiPlugins
			.post(`lgpd/cookies/setConsentStatus`,
				{
					UID  : this.getGuestUniqueID(),
					token  : this.config.token,
					sessionToken  : this.config.sessionToken,
					domain : window.location.hostname,
					status,
					document : document || this.config.userDocument,
				}
			)
			.then(res => res.data)
			.then(consent => {
				if (consent?.accepted)
					this.fireEvent('onCookiesConsentAccepted', null, true)
				return consent
			})
	}

	async updateConsentDocument(document, sessionToken=null) {
		return apiPlugins
			.post(`lgpd/cookies/updateConsentDocument`,
				{
					UID  : this.getGuestUniqueID(),
					token  : this.config.token,
					sessionToken  : sessionToken || this.config.sessionToken,
					domain : window.location.hostname,
					document : document || this.config.userDocument,
				}
			)
			.then(res => res.data)
	}

	async updateConsentDocumentPublic(document) {
		return apiPlugins
			.post(`lgpd/cookies/updateConsentDocumentPublic`,
				{
					UID  : this.getGuestUniqueID(),
					token  : this.config.token,
					sessionToken  : this.config.sessionToken,
					domain : window.location.hostname,
					document : document,
				}
			)
			.then(res => res.data)
	}

}

export default RegisterPlugin('cookies', Cookies)

