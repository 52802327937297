import React, {useEffect, useState} from "react"

//import InputMask from "react-input-mask";
import CurrencyInput from '../SubComponents/CurrencyInput'
import { AvField } from "availity-reactstrap-validation"
import { Input } from 'reactstrap';
// import { parseMoney } from '../../../../helpers/parser_helper'
// import {isValidMoney} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";
import {parseMoney, parsePercent} from "../../../../helpers/parser_helper";
import {MoneyFormat, PercentFormat} from "../../../../helpers/utils";

function FieldMoney(props) {

    const [value, setValue] = useState(props.modelValue)
    const [isEmpty, setIsEmpty] = useState(false)
	const isEmptyFieldValue = props.isEmptyFieldValue

	useEffect(() => {
		setIsEmpty(isEmptyFieldValue, props)
	}, [props.isEmptyFieldValue])

	useEffect(() => {
		setValue(props.modelValue)
	}, [props.modelValue])

    const handleChange = (evt) => {
    	setValue(evt.target.value)
		//console.log(e.target.value)
        if (props.onChange)
            props.onChange(props.name, evt.target.value)
    }

    // const handleFocus = (evt) => {
	// 	// const isEmpty = props.isEmptyFieldValue(evt.target.value, props)
	// 	if (isEmpty) {
	// 		evt.target.value = ''
	// 	}
    // 	// setValue(e.target.value ? MoneyFormat(parseMoney(e.target.value), 'R$ ', 0) : "")
    //     if (props.onFocus) props.onBlur(evt)
	// }

    // const {caption, type, inputRef, formId, screenId, validation, ...rest} = props
	const rest = getValidDomProperties(props)
	// console.log('value', parseMoney(value))

	const placeHolder = props.type==='percent' ? "" : props.maskOptions?.allowDecimal ? "R$ 0,00" : "R$ 0"

	let parsedValue
	if (props.type==='percent') {
		parsedValue = value ? PercentFormat(parsePercent(value), '%', 2) : ""
	} else {
		parsedValue = value ? MoneyFormat(parseMoney(value), 'R$ ', props.maskOptions?.allowDecimal?2:0) : ""
	}


//	console.log('parsedValue', parsedValue)
	//const isEmptyFieldValue = props.isEmptyFieldValue(parsedValue, props)
	// console.log('tesst', {
	// 	isFocused:props.isFocused,
	// 	isEmpty,
	// 	parsedValue,
	// })
    return (
        <AvField
            ref={props.inputRef || undefined}
            className={`form-control ${isEmpty && "empty-money-white"}`}
            tag={[Input, CurrencyInput]}
            {...rest}
			// value={props.value == 0 ? "" : props.value}
			value={parsedValue}
            placeHolder={
            	!props.isFocused && isEmpty
					? ""
					: placeHolder
            }
            // maskOptions={{allowNegative:props.allowNegative || false}}
            maskOptions={props.maskOptions || {}}
            autoComplete="disabled"
            type="text"
			inputMode="decimal"
            onChange={handleChange}
            // onFocus={handleFocus}
			// onFocus={(e) => {try {e.target.select()} catch(e) {} } }
			required={props.required}
            // validate={{val:() => isValidMoney(value, props) }}
            validate={props.validation}
        />
    )
}

export default FieldMoney;
