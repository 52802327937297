import React, {useEffect, useState} from "react"
import AutoIcon from "../AutoIcon";
import {Spinner} from "reactstrap";

const IconClass = ({className, iconStyle, ...props}) => {
	return <span
		className={className}
		style={iconStyle}
		{...props}
	/>
}

function ButtonIcon (
	{
		loading=false,
		className,
		iconStyle = undefined,
		icon,
		width,
		height,
		disabled,
		title,

		reverse=false,
		center=true,
		column=false,
		caption=null,
		hoverColor="var(--bs-fiduc-green)",
		color="var(--bs-fiduc-black)",
		onClick,
		...props
	}
	) {

	const [isHovered, setIsHovered] = useState(false)
	const [layoutClass, setLayoutClass] = useState(false)

	useEffect(() => {
		if (column) {
			setLayoutClass(!reverse ? "flex-column-reverse" : "flex-column")
		} else {
			setLayoutClass(!reverse ? "flex-row-reverse" : "flex-row")
		}
	}, [reverse, column])

    return (
    	<button
			onMouseOver={() => setIsHovered(true)}
			onMouseOut={() => setIsHovered(false)}
			onTouchStart={() => setIsHovered(true)}
			onTouchEnd={() => setIsHovered(false)}
			className={`f-btn-icon debug2 cursor-pointer d-flex ${layoutClass} justify-content-center align-items-center ${className}`}
			onClick={onClick}
			title={title}
			disabled={disabled}
			type="button"
		>
			{caption &&
				<div
					style={{
						color:isHovered && !disabled ? hoverColor : color
					}}
					className={`
					debug flex-fill flex-grow-1 px-1  d-flex align-items-center
					${center ? "justify-content-center" : "justify-content-start"}					
					`} >
					<div>{caption}</div>
				</div>
			}
			{icon &&
				<div className="debug4 f-btn-icon-text flex-grow-0 d-flex justify-content-center align-items-center">
				  {loading
					  ?
						<Spinner
						  style={{ width: width, height: height }}
						  color="dark"
						/>
					  :
						<AutoIcon
							icon={icon}
							style={{color:isHovered && !disabled ? hoverColor : color, ...iconStyle}}
							width={width}
							height={height}
						/>
				  }


				</div>
			}
		</button>
	)
}

export default ButtonIcon;
