import api, {sleep} from "../../helpers/api/api";


export const loadById = async ( { state, actions }, id ) => {

	return api
		.get(`channels/${id}`)
		.then(async res => {
			return {
				...res.data,
				active : res.data.active===1 ? "1" : "0"
			}
		})
}

export const save = async ( { state, actions }, data ) => {

	const request = data.id
		? api.put(`channels/${data.id}`, data)
		: api.post(`channels/`, data)

	return await request
		.then(res => res.data)
}

export const deleteById = async ( { state, actions }, id ) => {
	return api.delete(`channels/${id}`)
		.then(res => res.data)
}

