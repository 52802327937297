import React, { useState } from "react"

import Field from "./Field";
import { useOvermind } from '../../../overmind'
import { Row } from "reactstrap";

function FormWizard(props) {

    const {
    	formId,
		screens,
		selectedScreen=null,
		validationInRealTime=false,
		OnInputValueChange = () => {},
    } = props

	const screenList = screens

    const {
        state   : {
            [formId]:{currentTab, currentScreen, model}
        },
        actions : {

        }
    } = useOvermind('forms')



    const screen = selectedScreen!==null
		? screenList[selectedScreen]
		: screenList[currentScreen]

    if (!screen)
		return null

	// const getFieldRequired = (required) => {
	// 	return typeof required != 'function'
	// 		? () => required ? true : false
	// 		: () => required(model)
	// }

	if (screen.component) {
		const SComponent = screen.component
		return <SComponent formId={formId}/>
	}

    return (
        <Row className={`form-wizard form-wizard-${screen.id} w-100`} style={{}}>
            {screen.fields.map((field, fieldIndex) => {
				//field.required = getFieldRequired(field.required)

				const isFirstColumn = fieldIndex===0
				const isLastColumn = fieldIndex===screen.fields.length-1
				if (field.component) {
					return typeof(field.component)=='object' ? field.component : <field.component
                        key={field.id}
                        formId={formId}
					/>
				}

                return (
                    <Field
                        formId={formId}
						validationInRealTime={validationInRealTime}
                        key={field.id}
                        screenId={screen.id}
                        screenIndex={currentScreen}
						customFormatter={props.customFormatter}
                        {...field}
						OnInputValueChange={OnInputValueChange}
						tabId={props.tabId}
						fieldIndex={fieldIndex}
						isFirstColumn={isFirstColumn}
						isLastColumn={isLastColumn}
                    />
                )
            })}
        </Row>
    )
}

export default FormWizard;
