import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';

import Header from "../../VerticalLayout/Header"

import {environment} from "../../../helpers/api/configuration";

import {useOvermind} from "../../../overmind";

function DefaultPage( {children, ...props} ) {
	const [preloaded, setPreloaded] = useState(false)
	const app = useOvermind('app')
	useEffect(() => {
		app.actions.loadRemoteLists()
			.then(() => {
				setPreloaded(true)
			})
	}, [])

	const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
	const leftSideBarType = 'default'

	const toggleMenuCallback = () => {
		if (leftSideBarType === "default") {
			//dispatch(changeSidebarType("condensed", isMobile))
		} else if (leftSideBarType === "condensed") {
			//dispatch(changeSidebarType("default", isMobile))
		}
	}

	// console.log('leftSideBarType', leftSideBarType)

	if (!preloaded)
		return null

	const pageTitle = environment==='production'
		? 'Fiduc Serviços - Admin'
		: `[${environment}] Fiduc Serviços - Admin`

	return <>
		<div className="page-content">
			<MetaTags>
				<title>{pageTitle}</title>
			</MetaTags>
			<Header toggleMenuCallback={toggleMenuCallback} headerComponent={props.headerComponent}/>
			{children}
		</div>
	</>
}

export default DefaultPage;
