import React, {useState} from "react"

import InputMask from "react-input-mask";
import { AvField } from "availity-reactstrap-validation"
import { Input } from 'reactstrap';
import {isValidNumber} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";

function FieldNumber(props) {

    // const [value, setValue] = useState(props.modelValue)
    const handleChange = (e) => {
        // setValue(e.target.value)
        if (props.onChange)
            props.onChange(props.name, e.target.value, true, true)
    }

    // const {caption, type, inputRef, formId, screenId, validation, ...rest} = props
	const rest = getValidDomProperties(props, ['maxLength'])
    return (
        <AvField
            ref={props.inputRef || undefined}
            className="form-control"
            mask={props.maxLength ? '9'.repeat(props.maxLength)  : "99999999999999999999999999999999999"}
            maskChar=""
            tag={[Input, InputMask]}
            {...rest}
			value={props.modelValue}
            autoComplete="disabled"
            type="text"
			inputMode="decimal"
            onChange={handleChange}
			required={props.required}
            // validate={{val:() => isValidNumber(value, props) }}
            validate={props.validation}
			// maxLength={props.maxLength || undefined}
        />
    )
}

export default FieldNumber;
