import React, { useEffect } from "react"

import { withRouter } from "react-router-dom"

// Layout Related Components
import Sidebar from "./Sidebar"
// import Rightbar from "../CommonForBoth/RightSidebar"

//redux
// import ContainerSwap from "../../pages/SearchBar/ContainerSwap";
// import DefaultPage from "../Common/DefaultPage/DefaultPage";
import lStorage from "../../helpers/api/lStorage";

const Layout = props => {

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const leftSideBarType = lStorage.get('sidebarType')


  /*
  layout  settings
  */

  useEffect(() => {
	  document.body.setAttribute("data-topbar", "light")
	  document.body.setAttribute("data-topbar", "light")
	  document.body.setAttribute("data-layout", "vertical")
	  document.body.setAttribute("data-sidebar", "dark")
	  document.body.setAttribute("data-sidebar-image", "none")
	  document.body.setAttribute("data-sidebar-size", "")
	  document.body.setAttribute("data-layout-size", "fluid")
	  document.body.setAttribute("data-layout-scrollable", "false")
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Sidebar
          theme={'dark'}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div id='main-content' className="main-content">
			{props.children}
			{/*<ContainerSwap component={props.children} />*/}
        </div>
        {/*<Footer />*/}
      </div>

    </React.Fragment>
  )
}

export default withRouter(Layout)
