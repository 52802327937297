import React from "react"
import { Redirect } from "react-router-dom"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import {UsersSearch} from "../pages/Users/Search";
import {ProfilesSearch} from "../pages/Profiles/Search";
import MyAccountView from "../pages/MyAccount/View";
import {ChannelsSearch} from "../pages/Channels/Search";
import TestPluginPage from "../pages/TestPlugins";
import CookiesSearch from "../pages/Cookies/Search";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/cookies/search/public", component: function A(props) { return <CookiesSearch key={Date.now()} {...props} searchType={"public"} /> }},
  { path: "/cookies/search/restricted", component: function A(props) { return <CookiesSearch key={Date.now()} {...props} searchType={"restricted"} /> }},
  { path: "/users/search", component: function A(props) { return <UsersSearch key={Date.now()} {...props}  /> }},
  { path: "/profiles/search", component: function A(props) { return <ProfilesSearch key={Date.now()} {...props}  /> }},
  { path: "/channels/search", component: function A(props) { return <ChannelsSearch key={Date.now()} {...props}  /> }},
  { path: "/myAccount", component: function A(props) { return <MyAccountView key={Date.now()} {...props}  /> }},

  { path: "/testPlugins", component: function A(props) { return <TestPluginPage key={Date.now()} {...props}  /> }},


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },

]

export { publicRoutes, authProtectedRoutes }
