
import axios from 'axios';
import {environment, fiducUrl} from './configuration'
import {AuthInfo, Logout} from "./auth";

// const https =  require('https');

const apiPlugins = axios.create({
	baseURL: fiducUrl,
	proxy: false,
	crossDomain: true,
	//withCredentials: true,
	httpsAgent: { rejectUnauthorized: true },
	timeout: 60000*5,
});

/*
const api = wrapper(http, {
  maxCacheSize: 15
}) */

/*
api.__addFilter(/users\/car-brands/)
api.__addFilter(/users\/car-models/)
api.__addFilter(/users\/car-colors/)
*/
/*

*/

export const sleep = (ms) => {
	return function(x) {
		return new Promise(resolve => setTimeout(() => resolve(x), ms));
	};
}


apiPlugins.interceptors.request.use(

	async (request) => {
		// const loginInfo = AuthInfo();

		request.headers.Accept = 'application/json';

		// if (loginInfo && loginInfo.token) {
		// 	request.headers.Authorization = loginInfo.token;
		// }

		return request;
	},

	async (error) => {
		console.log('request-error', error)

		return error;
	}


);

apiPlugins.interceptors.response.use(
	(response) => response,
	async (error) => {

		if (error.response) {
			// Request made and server responded
			console.log('Response Error: ', error.response);
		} else if (error.request) {
			// The request was made but no response was received
			// console.log('Request error: ', { error, request: error.request });
			console.log('Request error: ', error);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
		}

		return Promise.reject(error);
	}
);

export default apiPlugins;
