import React, {useEffect, useState} from "react"
import {instanceOf} from "prop-types";

function AutoIcon(
{
	icon,
	width='auto',
	height='auto',
	className,
	style,
	...props
}) {
	const Icon = icon

	const isComponent = icon?.render
	const isSrcImage = typeof icon === 'string' && icon.indexOf('data:') > -1
	const isClassName = !isSrcImage

	if (isComponent) {
		return (
			<div className={`d-inline-flex ${className}`} style={style}>
				<Icon width={width} height={height} />
			</div>
		)
	}

	if (isSrcImage) {
		return (
			<div className={`d-inline-flex ${className}`} style={style}>
				<img src={icon} width={width} height={height} />
			</div>
		)
	}


	if (isClassName) {
		const strSize = `${(width && width!=='auto') ? width : (height && height!=='auto') ? height : ''}`
		// const strSize = `${(height && height!=='auto') ? height : ''}`
		return (
			<div
				className={`d-flex justify-content-center align-items-center ${className} overflow-hidden`}
				style={style}
			>
				<div
					style={{fontSize:height, width:strSize}}
					className={`auto-icon-text text-align-center ${icon}`}
				/>
			</div>
		)
	}


	return null

}

export default AutoIcon;
