import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"

//import { render } from 'react-dom'
import { createOvermind } from 'overmind'
import { config } from './overmind/index'
import { Provider as OvermindProvider } from 'overmind-react'


const overmind = createOvermind(config, { devtools: false })
//const overmind = createOvermind(config)
// import "./components/CommonForBoth/rightbar.scss"


export const Index = (
	<OvermindProvider value={overmind}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</OvermindProvider>
)

ReactDOM.render(Index, document.getElementById("root"))
serviceWorker.unregister()
