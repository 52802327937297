import React from "react"

function LoadingDots({
	animating=true,
	theme="light",
	visible=true,
	...props
}) {
	return (
		<div
			className={`debug1 ${animating ? "" : "dot-flashing-no-anim"} dot-flashing-${theme}`}
			style={{visibility:visible ? "visible" : "hidden"}}
		/>
	)

}

export default LoadingDots;
