import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import {Logout} from "../../helpers/api/auth";

const LogoutPage = props => {

	useEffect(() => {
		Logout()
		props.history.push('/login')
	}, [])

	return <></>
}

Logout.propTypes = {
	history: PropTypes.object,
}

export default withRouter(LogoutPage)
