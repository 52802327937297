import React, {useState, useEffect, useMemo} from 'react';

import api, { sleep } from "../../../helpers/api/api"

import {useOvermind, useOvermindForm} from "../../../overmind";
import {Datatable} from "../../../components/Common/Tables/Datatable";
import CustomHeader from "../../../components/Common/Tables/Datatable/CustomHeader";

import CustomForm from "../../../components/Common/CustomForm/Form";
import DefaultPage from "../../../components/Common/DefaultPage/DefaultPage";

import FormWizard from "../../../components/Common/CustomForm/FormWizard";

import ProfilesView from "../View";

import PageDatatable from "../../../components/Common/Tables/Datatable/PageDatatable";
import DefaultPageHeader from "../../../components/Common/DefaultPage/DefaultPageHeader";
import {downloadFileClient} from "../../../helpers/url";
import {DataActionButton} from "../../../components/Common/Tables/Datatable/DataTypes";
import UserCanAll from "../../../components/Common/UserCan/UserCanAll";
import {UserCan} from "../../../helpers/api/auth";
import {useLocalStorage} from "../../../helpers/api/UseLocalStorage";
import ButtonPrimary from "../../../components/Common/Button/ButtonPrimary";

const mainTabs = [
	{id:'all', caption:"Busca"},
]

const apiOptions = [
	{id:'consult', caption:"Consultar"},
	{id:'edit', caption:"Modificar"},
	// {id:'exclude', caption:"Excluir"},
	// {id:'include', caption:"Incluir"},
	// {id:'approve', caption:"Aprovar"},
]

const apiEndpoint = {
	path : `profiles/search`,
	method : `post`,
}

export const ProfilesSearch = () => {
	const formId = 'ProfilesSearch'

	const [searchParams, setSearchParams] = useState(null);
	const [filterParams, setFilterParams] = useState({});
	// const [filterStatus, setFilterStatus] = useState(history.location?.state?.status || 'all');
	// const [selectedPeriod, setSelectedPeriod] = useState(null)
	const [selectedIds, setSelectedIds] = useState([])
	const [compactMode, setCompactMode] = useLocalStorage(`compact-mode-${formId}`, true)

	const [exportingResult, setExportingResult] = useState(false)
	const [viewData, setViewData] = useState(null)

	const form = useOvermindForm(formId)
	const profiles = useOvermind('profiles')

	const handleExportResult = (evt, fileTitle=null) => {

		setExportingResult(true)
		api
			.post(`profiles/downloadCsv`, searchParams)
			.then(sleep(5000))
			.then(res => downloadFileClient(res, fileTitle))
			.catch(e => alert(`Ocorreu um erro ao fazer o download: ${e.toString()}`))
			.finally(() => setExportingResult(false))
	}

	const refreshList = () => {
		setSearchParams({
			...filterParams,
			// periodStart : model?.period?.length && model?.period[0],
			// periodEnd : model?.period?.length && model?.period[1],
		})
	}

	useEffect(() => {
		refreshList()
	}, [filterParams])

	const handleFilterChange = () => {
		const newParams = {
			...filterParams,
			..._.cloneDeep(form.state.model),
			//value : parseMoney(model.value) ,
		}
		console.log('handleFilterChange', newParams)
		setFilterParams(newParams)
	}

	const handleClick = row => {
		profiles.actions.loadById(row.id)
			.then(data => {

				//setData(record)
				const record = {
					id : data.id,
					name : data.name,
					apis : {}
				}
				for (const api of data.apis) {
					if (!api.api)
						continue
					if (!record.apis[api.api.id]) {
						record.apis[api.api.id] = []
					}
					for (const option of apiOptions) {
						if (api[option.id])
							record.apis[api.api.id].push(option.id)
					}
				}
				setViewData(record)
			})
			.catch(e => alert(`Ocorreu um erro ao carregar os dados`))
	};

	const handleNew = async () => {
		setViewData({})
	};

	const handleDelete = async (row) => {
		if (confirm(`Deletar perfil "${row.name}" ?`)) {
			profiles
				.actions
				.deleteById(row.id)
				.then(() => {
					refreshList()
				})
				.catch((e) => {
					const errCode = e.response?.data?.error?.message
					if (errCode==='being_used') {
						alert(`\nNão foi possível remover este perfil, ele está sendo utilizado por algum usuário.`)
					} else {
						alert('Ocorreu um erro ao tentar deletar este perfil')
					}
				})
		}
	};

	const columns = useMemo(() => [
		// {
		// 	id : 'id',
		// 	name: <CustomHeader onComplete={handleFilterChange} id={'id'} formId={formId} fieldProps={{type:'number'}}>ID</CustomHeader>,
		// 	grow:0,
		// 	width:'64px',
		// 	selector: row => row?.id,
		// 	sortable: true,
		// 	compact : true,
		// 	wrap : true,
		//
		// },
		{
			id : 'name',
			name: <CustomHeader onComplete={handleFilterChange}  id={'name'} formId={formId} fieldProps={{type:'text'}}>Nome do perfil</CustomHeader>,
			grow:1,
			minWidth:'128px',
			selector: row => row?.name,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'actions',
			name: "Ações",
			grow:0,
			minWidth:'72px',
			center:true,
			omit : !UserCan('profiles.edit'),
			selector: function A(row) {
				return <>
					<DataActionButton
						disabled={row.id==1}
						buttonClass="btn btn-outline-danger m-1 btn-sm"
						iconClass="fas fa-trash m-1"
						onClick={() => handleDelete(row)}
					/>
				</>
			},
			sortable: false,
			compact : true,
			wrap : true,
		},
	], [compactMode]);

	const searchFields = useMemo(() => [
	], []);

	return (
		<UserCanAll permission="profiles.consult">
		<ProfilesView
			viewData={viewData}
			apiOptions={apiOptions}
			onConfirm={() => {
				setViewData(null)
				refreshList()
			}}
			onCancel={() => setViewData(null)}
		/>
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title="Perfis de usuário"  />
			}
		>
			<CustomForm
				className="needs-validation"
				formId={formId}
				model={form.state.model}
				validateOnLoad={false}
			>
				<PageDatatable
					formId={formId}
					tabs={mainTabs}
					activeTab={'all'}
					onTabChange={null}
					TabActions={<>
						<UserCanAll permission="profiles.edit">
						<ButtonPrimary
							caption="Adicionar Perfil"
							small={true}
							className="m-1"
							icon={'fas fa-plus'}
							onClick={handleNew}
						/>
						</UserCanAll>
						<ButtonPrimary
							caption="Exportar"
							outlined={true}
							small={true}
							className="m-1"
							icon={'fa fa-file-excel'}
							onClick={handleExportResult}
							loading={exportingResult}
						/>
					</>}
					HeadContainer={
						<FormWizard
							screenId={formId}
							screens={searchFields}
							formId={formId}
							// OnInputValueChange={handleInputValueChange}
							tabId={formId}
						/>
					}
				>
					<Datatable
						heightSpace={'220px'}
						selectableRows={false}
						onChangeSelectedRows={(selectedRows) => {
							setSelectedIds(_.map(Object.values(selectedRows), 'id'))
						}}
						onRowClicked={(row, e) => handleClick(row)}
						keyField={formId}
						formId={formId}
						columns={columns}
						searchParams={searchParams}
						apiEndpoint={apiEndpoint}
					/>
				</PageDatatable>

			</CustomForm>
		</DefaultPage>
		</UserCanAll>
	);
};

export default ProfilesSearch
