import React, {useEffect, useMemo, useState} from "react"

import { useOvermind } from '../../../overmind'
import {yesNoOptions} from "../../../constants/formLists";
import {onlyNumbers, dateBrToISO} from "../../../helpers/utils";
import {UserCan} from "../../../helpers/api/auth";
import UserCanAll from "../../../components/Common/UserCan/UserCanAll";
import ModalForm from "../../../components/Common/Modals/ModalForm";


function UsersView(
	{
		viewData,
		onConfirm = () => {},
		onCancel = () => {},
	}
) {

	const users = useOvermind('users')
	const [saving, setSaving] = useState(false)
	const readOnly = !UserCan('users.edit')

	const handleSave = async (values) => {

		//console.log(values)

		const data = {
			...values,
            document : onlyNumbers(values.document),
			birthDate : values.birthDate ? dateBrToISO(values.birthDate) : null,
			initialDate : values.initialDate ? dateBrToISO(values.initialDate) : null,
			active : parseInt(values.active),
			profileId : parseInt(values.profileId || 0),
		}

    	setSaving(true)
    	users.actions.save(data)
			// .then(sleep(1000))
			.then(onConfirm)
			.catch((e) => {
				const errCode = e.response?.data?.code
				if (errCode=='ERR087') {
					alert('Não foi possível salvar, este CPF já está cadastrado')
				} else {
					alert(`Ocorreu um erro ao salvar: ${e.toString()}`)
				}

			})
			.finally(() => setSaving(false))

	}

	const viewForm = useMemo(() => [
		// {
		// 	id:'titleUser',
		// 	caption:'Detalhes do Usuário',
		// 	type:'title',
		// },
		{
			id:'id',
			type:'hidden',
		},
		{
			id:'name',
			caption:'Nome Completo',
			type:'text',
			required:true,
			row:true,
			readOnly,
			col:7,
		},
		{
			id:'profileId',
			caption:'Perfil',
			type:'select2',
			listId:'UserProfile',
			required:true,
			readOnly,
			col:5
		},
		{
			id:'document',
			caption:'CPF',
			type:'cpf',
			required:true,
			readOnly,
			col:12
		},
		{
			id:'email',
			caption:'E-mail',
			type:'email',
			required:true,
			readOnly,
			col:12
		},
		{
			id:'phone',
			caption:'Telefone',
			type:'phone',
			required:true,
			readOnly,
			col:12
		},
		{
			id:'birthDate',
			caption:'Data de Nascimento',
			type:'date',
			required:true,
			readOnly,
			col:12
		},
		{
			id:'initialDate',
			caption:'Data de Início',
			type:'date',
			required:false,
			readOnly,
			col:12
		},
		{
			id:'active',
			caption : 'Ativo?',
			type    : 'radiogroup',
			options:yesNoOptions,
			required:true,
			col:12,
			readOnly,
			inline:true,
		},
	], []);


    return (
        <UserCanAll permission="users.consult">
			<ModalForm
				data={viewData}
				isVisible={!!viewData}
				fields={viewForm}
				saving={saving}
				modalStyle={{
					maxWidth : '50%',
				}}
				onCancel={onCancel}
				onConfirm={handleSave}
				mainTabCaption={"Detalhes do Usuário"}
			/>
        </UserCanAll>
    )
}

export default UsersView;
