import { merge, namespaced } from 'overmind/config'
import { state } from './state'

import {
	createStateHook,
	createActionsHook,
	createEffectsHook,
	createReactionHook,
} from 'overmind-react'

import * as app from './app'
import * as forms from './forms'
import * as login from './login'
import * as users from './users'
import * as profiles from './profiles'
import * as channels from './channels'
import * as cookies from './cookies'

export const config = merge(
	{
		state
	},
	namespaced({
		app,
		forms,
		login,
		users,
		profiles,
		channels,
		cookies,
	})
)

export const useAppState = createStateHook()
export const useActions = createActionsHook()
export const useEffects = createEffectsHook()
export const useReaction = createReactionHook()


export function useOvermind (namespace) {
	return {
		state     : namespace ? useAppState()[namespace] : useAppState(),
		actions   : namespace ? useActions()[namespace] : useActions(),
		effects   : namespace ? useEffects()[namespace] : useEffects(),
		reactions : namespace ? useReaction()[namespace] : useReaction(),
	}
}


export function useOvermindForm (formId) {
	const overmindObj = useOvermind('forms')
	return {
		state : overmindObj?.state[formId],
		actions : overmindObj?.actions
	}
}


