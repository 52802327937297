import React, {useEffect, useMemo, useState} from "react"

import Cookies from "plugins/cookies"
import ButtonPrimary from "../../../components/Common/Button/ButtonPrimary";
import CookiesVisualFs from "../../../plugins/cookies-visual-fs/CookiesVisualFS";

function TestPluginCookiesVisualFs(props) {


	useEffect(() => {


		Cookies.on('onConsentAccepted', () => {
			alert('accepted')
		})

		Cookies.on('onConsentDenied', () => {
			alert('denied')
		})

		return () => {
			Cookies.clearEvents(['onConsentAccepted', 'onConsentDenied'])
		}

	}, [])


	return <>
		<cookiesvisualfs>
			<CookiesVisualFs
				pluginParams={{
					token:'66bc0dda-bcd9-4c3d-9337-3c82083a9fb5',
					//userDocument:AuthInfo()?.document,
					strict:false, //Can't close window until accepts
				}}
			/>
		</cookiesvisualfs>
		<div className="d-flex justify-content-center align-items-center">
			<ButtonPrimary
				caption="Mostrar"
				className="m-2"
				onClick={() => Cookies.fireEvent('onShowConsent')}
			/>
		</div>
	</>
}

export default TestPluginCookiesVisualFs;
