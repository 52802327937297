import React, {useState, useEffect, useMemo, useRef} from 'react';
import {default as ReactDataTable} from 'react-data-table-component';

import api, { sleep } from "../../../../helpers/api/api"
import {
	Col, Row, Spinner,
} from "reactstrap";

// import { useLocation, useHistory } from "react-router-dom"
// import CustomHeader from "./CustomHeader";

import { useOvermind } from "../../../../overmind";
import { customStyles } from "./styles"

//import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import {useLocalStorage} from "../../../../helpers/api/UseLocalStorage";
// import Field from "../../CustomForm/Field";

const ProgressComponent = () => {
	return  (
		<div className="no-pointer-events d-inline-block" style={{position:'absolute', left:'.2rem', border:'solid 0px red', height:'10em', top:'-4em', zIndex:1000, padding:'1em', backgroundColor:'white', opacity:1}}>
			<Spinner
				style={{ width: "1em", height: "1em"  }}
				color="primary"
			/>
		</div>
	)
}

const NoDataComponent = <Row style={{display:'contents'}}>
	<Col md={12}  className="pt-5 d align-self-start">Nenhum registro encontrado</Col>
</Row>

const paginationServerOptions = {
	persistSelectedOnSort : false,
}


const paginationComponentOptions = {
	rowsPerPageText: 'Resultados por página:',
	rangeSeparatorText: 'de',
	noRowsPerPage: false,
	selectAllRowsItem: false,
	selectAllRowsItemText: 'Todos'
}

let RequestCounter = 0

export const Datatable = ({
	searchParams = null,
	columns = {},
	heightSpace,
	selectableRows = true,
	defaultSortField = 'id',
	formId = '_',
	onChangeSelectedRows = null,
	onRowClicked,
	apiEndpoint = {
		path : '',
		method : 'post'
	},
}) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
//	const [perPage, setPerPage] = useState(10);
	const [perPage, setPerPage] = useLocalStorage('DT-perPage', 30);
	const [page, setPage] = useState(1);
	const [orderBy, setOrderBy] = useState('id');
	const [orderDirection, setOrderDirection] = useState('desc');
	const [toggledClearRows, setToggleClearRows] = React.useState(false);

	const [selectedRows, setSelectedRows] = React.useState([]);

	const handleRowSelected = React.useCallback(state => {
		//state.selectedRows.forEach(item => item.selected = true)
		setSelectedRows(state.selectedRows);
		onChangeSelectedRows(state.selectedRows)

	}, []);

	const app = useOvermind('app')
	//const formId = 'SearchBar'

	// const {
	// 	state   : {
	// 		[formId] : {Form, model}
	// 	},
	// 	actions : {
	// 		submitForm, setModelValueByPath,
	// 		setFormState, setFieldState, setFormModel, resetForm,
	// 		updateModelValueValues, incFormValidatedCount, incFormResetCount
	// 	}
	// } = useOvermind('forms')

	const fetchData = () => {

		setLoading(true);
		//console.log('orderDirection', orderDirection)

		return api[apiEndpoint.method](apiEndpoint.path, {
			...searchParams,
			pagination : {
				//count : totalRows,
				page : page,
				limit : perPage,
				sortField : orderBy,
				sortDirection : orderDirection,
				RequestCounter:++RequestCounter,
			},
		})
		//.then(sleep(2000))
		.then(response => {
			if (RequestCounter==response.data?.pagination?.RequestCounter) {
				setData(response?.data?.result);
				setTotalRows(response?.data?.pagination?.count);
				setLoading(false);
			} else {
				console.log('dropped response data:', {data, RequestCounter})
			}
		})
		.catch(e => {
			alert(e.toString())
		})
		.finally(() => {
			setLoading(false);
		});

	};

	useEffect(() => {
		//console.log(location)
		//if (!location.state?.fromSearch) app.actions.setSearchString('')
		setToggleClearRows(!toggledClearRows);

		if (searchParams) {
			//console.log(`DATATABLE searcgParansUpdate`)
			// console.log('searchParams', searchParams)
			fetchData()
		}
	}, [
		page, perPage, orderBy, orderDirection, searchParams
	]);

	const handlePageChange = page => {
		setPage(page)
	};

	const handlePerRowsChange = (newPerPage, page) => {
		//console.log('handlePerRowsChange', {newPerPage, page})
		setPage(page);
		setPerPage(newPerPage);
	};

	const handleSortChange = (row, orderDir) => {
		//console.log('handleSortChange', {id:row.id, orderDir, from:orderDirection})
		setOrderDirection(orderDir)
		setOrderBy(row.id)
	};

	const styles = useMemo(() => {
		return {
			...customStyles,
			table : {
				style : {
					height:`calc(100vh - ${heightSpace || '280px'})`,
					width:'100%',
					overflowX:'hidden',
					flex:0,
				}
			}
		}
	}, [heightSpace])

	return (
		<ReactDataTable
			progressComponent={<ProgressComponent />}
			progressPending={loading}
			columns={columns}
			noHeader
			customStyles={styles}
			data={data}
			pagination
			paginationServer
			paginationTotalRows={totalRows}
			sortServer
			onChangeRowsPerPage={handlePerRowsChange}
			paginationPerPage={perPage}
			paginationRowsPerPageOptions={[10,20,30,40,50,100]}
			selectableRows={selectableRows}
			persistTableHead
			highlightOnHover
			selectableRowsHighlight
			striped
			onRowClicked={onRowClicked}
			expandOnRowClicked={false}
			defaultSortAsc={true}
			defaultSortField={defaultSortField}
			onChangePage={handlePageChange}
			onSort={handleSortChange}
			paginationServerOptions={paginationServerOptions}
			paginationComponentOptions={paginationComponentOptions}
			noDataComponent={NoDataComponent}
			clearSelectedRows={toggledClearRows}
			onSelectedRowsChange={handleRowSelected}

			// conditionalRowStyles={conditionalRowStyles}
		/>
	);
};

export default {
	title: 'Pagination/Remote',
	component: Datatable,
};
