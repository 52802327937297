import React from "react"

//import { AvField, AvFeedback } from "availity-reactstrap-validation"
import {CardSubtitle, CardTitle, Col, Row} from "reactstrap";

function FormTitleDefault(props) {

    const {caption, subtitle, type, center, condensed, inputRef, formId, ...rest} = props
    return (
        <div className={`
			debug2 d-flex 
			${condensed && "condensed"}        
        `}>
            <div className="">
				{caption &&
                <div className={`container-title debug mb-0 mt-0 font-family-title ${center ? "text-center" : ""}`}
					 style={{
					 	// fontSize:'1.25rem',
						fontSize:'2.5vh',
					 }}
				>
                    {caption}
                </div>
				}
                {subtitle && (
                <div className="container-subtitle mt-2 mb-2 px-2 " style={{
                	fontWeight:'normal',
					// fontSize:'4.5vh',
                }}>
                    {subtitle}
                </div>
                )}
            </div>
        </div>
    )
}

export default FormTitleDefault;
