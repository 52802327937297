import React, {useEffect, useMemo, useState} from "react"

const FiducPlugin = require("../../../components/Plugins/FiducPlugin")

// const Cookies = require("plugins/cookies")
import Cookies from "plugins/cookies"
import ButtonPrimary from "../../../components/Common/Button/ButtonPrimary";
import ButtonSecondary from "../../../components/Common/Button/ButtonSecondary";
import {useOvermind} from "../../../overmind";

function TestPluginCookiesStandalone(props) {

	const login = useOvermind('login')
	const [consent, setConsent] = useState(null)

	const getConsentStatus = async () => {
		return Cookies
			.getConsentStatus()
			.then(data => {
				setConsent(data)
				console.log(data)
			})
	}

	const setConsentStatus = (status) => {
		Cookies
			.setConsentStatus(status)
			.then(getConsentStatus)
	}

	useEffect(() => {

		Cookies.addConfig({
			token:'66bc0dda-bcd9-4c3d-9337-3c82083a9fb5'
		})
		getConsentStatus()

		// alert(Cookies.config.token)
		// console.log(Cookies)

		// console.log(AuthInfo())
	}, [])


    return (
		<>
			<br/><br/>
			<div className="text-align-center">
				<strong>
					Consent status:{" "}
					<span className="text-success font-weight-600 font-family-title font-size-rem4">{consent?.status || <strong className="text-danger">Não existente</strong>}</span>
				</strong>
			</div>

			<div className="d-flex justify-content-center align-items-center">
				<ButtonPrimary
					caption="Aceitar"
					className="m-2"
					onClick={() => setConsentStatus('accepted')}
				/>

				<ButtonSecondary
					caption="Negar"
					className="m-2"
					onClick={() => setConsentStatus('denied')}
				/>

			</div>
	</>
	)
}

export default TestPluginCookiesStandalone;
