import React, {useEffect, useMemo, useState} from "react"
import moment from "moment"
moment.locale('pt-BR')

import {
    Col,
    Row,
} from "reactstrap"

import {useOvermind, useOvermindForm} from '../../../overmind'
import { useLocation, useHistory } from "react-router-dom"

import LoadingSection from "../../../components/Common/LoadingSection/LoadingSection";

import FormWizard from "../../../components/Common/CustomForm/FormWizard";
import Button from "../../../components/Common/Button/Button"
import {yesNoOptions} from "../../../constants/formLists";
import {onlyNumbers, dateBrToISO} from "../../../helpers/utils";
import {AuthInfo, UserCan} from "../../../helpers/api/auth";
import UserCanAll from "../../../components/Common/UserCan/UserCanAll";
import CustomForm from "../../../components/Common/CustomForm/Form";
import DefaultPage from "../../../components/Common/DefaultPage/DefaultPage";
import DefaultPageHeader from "../../../components/Common/DefaultPage/DefaultPageHeader";
import PageDatatable from "../../../components/Common/Tables/Datatable/PageDatatable";
import {
	createFormFields,
	validateFieldStates,
	validateFormScreens
} from "../../../components/Common/CustomForm/Utils/InputTypes";
import ButtonPrimary from "../../../components/Common/Button/ButtonPrimary";

const statusTabs = [
	{id:'all', caption:"Dados pessoais"},
]

function MyAccountView(props) {

    const {

	} = props

	const formId = "MyAccount"

	const readOnly = !UserCan('users.edit')
	const viewId = AuthInfo().id
	const history = useHistory()

	const form = useOvermindForm(formId)

	const users = useOvermind('users')
	const [saving, setSaving] = useState(false)
	const [saved, setSaved] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	//const [data, setData] = useState(null)

    useEffect(() => {
    	setError(false)
    	setSaving(false)
    	setSaved(false)

		setLoading(true)
		// resetForm({formId})
		users.actions.loadById({formId, id:viewId})
			.then(record => {

				//setData(record)
			 	form.actions.setFormModel({formId, model:record})
				// setModelValueByPath({formId, path:'edit', value:record})
				// incFormValidatedCount({formId})

			})
			.catch(e => {
				setError(e.response?.data || e.toString())
			})
			.finally(() => {
				setLoading(false)
			})



    }, [viewId])

	const handleConfirm = async (values) => {

		const data = {
			...values,
            document : onlyNumbers(values.document),
			birthDate : values.birthDate ? dateBrToISO(values.birthDate) : null,
			initialDate : values.initialDate ? dateBrToISO(values.initialDate) : null,
		}

    	setSaving(true)
    	users.actions.save(data)
			// .then(sleep(1000))
			.then(() => {
				alert(`Dados pessoais atualizados!`)
				history.push({pathname:"/dashboard",})
			})
			.catch((e) => {
				const errCode = e.response?.data?.error?.message
				alert(`Ocorreu um erro ao salvar: ${e.toString()}`)
			})

	}

	const formView = useMemo(() => createFormFields(formId, [
		// {
		// 	id:'title',
		// 	caption:'Dados pessoais',
		// 	type:'title',
		// 	required:true,
		// 	row:true,
		// 	readOnly,
		// 	col:8,
		// },
		{
			id:'name',
			caption:'Nome Completo',
			type:'text',
			required:true,
			row:true,
			readOnly,
			col:12,
		},
		{
			id:'birthDate',
			caption:'Data de Nascimento',
			type:'date',
			required:true,
			readOnly,
			col:12
		},
		{
			id:'document',
			caption:'CPF',
			type:'cpf',
			required:true,
			readOnly,
			col:12
		},
		{
			id:'email',
			caption:'E-mail',
			type:'text',
			required:true,
			readOnly,
			col:12
		},
		{
			id:'phone',
			caption:'Telefone',
			type:'phone',
			required:true,
			readOnly,
			col:12
		},
	]), [])

    return (
        <UserCanAll permission="users.consult">
			<DefaultPage
				headerComponent={
					<DefaultPageHeader title="Minha conta"  />
				}
			>
				<LoadingSection loading={loading} error={error}>
					<PageDatatable
						formId={formId}
						tabs={statusTabs}
						activeTab={'all'}
						TabActions={null}
					>
						<CustomForm
							className="needs-validation"
							formId={formId}
							screens={formView}
							validateOnLoad={true}
							onSubmit={handleConfirm}
						>
						<Row className="pr-4 ps-4">
							<Col md={12} className="pt-2 ">
								<Row >
								<Col md={12} className="w-100 d-block align-items-start">
									<FormWizard
										screenId={formId}
										screens={formView}
										formId={formId}
										// OnInputValueChange={handleInputValueChange}
										tabId={formId}
									/>
								</Col>
								</Row>
								<Row >
									<Col md={12} className="d-flex align-items-center justify-content-center">
										{/*<Button*/}
										{/*	autoFocus*/}
										{/*	className="btn btn-outline-danger"*/}
										{/*	onClick={handleCancel}*/}
										{/*	style={{margin:'1em'}}*/}
										{/*>*/}
										{/*	Voltar*/}
										{/*</Button>*/}
										<ButtonPrimary
											loading={saving}
											// disabled={!!form.state.formValidationResult?.first}
											className="m-1 p-2 px-4"
											type="submit"
											caption="Salvar"
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						</CustomForm>
					</PageDatatable>
				</LoadingSection>
			</DefaultPage>
        </UserCanAll>
    )
}

export default MyAccountView;
