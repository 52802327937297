import React, {useEffect, useMemo, useState} from "react"
import {useOvermind, useOvermindForm} from '../../../overmind'
import {UserCan} from "../../../helpers/api/auth";
import {cookiesConsentStatusOptions} from "../../../constants/formLists";
import ModalForm from "../../../components/Common/Modals/ModalForm";
import CookiesHistory from "../History/CookiesHistory";
import {acceptAllFiles, acceptUploadTypeImageAndPdf, defaultFiducAcceptFiles} from "../../../helpers/api/configuration";

function CookiesView(props) {

    const {
		viewData,
		loading,
		searchType,
		onConfirm = () => {},
		onCancel = () => {},
	} = props

	const readOnly = !UserCan('cookies.edit')
	const cookies = useOvermind('cookies')
	const [saving, setSaving] = useState(false)
	const form = useOvermindForm('ModalForm')

	const handleSave = async (data) => {

		const uploadedFiles =  _.cloneDeep(form.state.uploadedFiles);
		const attachment = uploadedFiles?.attachment?.deleted ? "deleted" : uploadedFiles?.attachment?.base64String

		// console.log(uploadedFiles)
		// return

    	setSaving(true)
    	cookies.actions.save({
			...data,
			attachment
    	})
			// .then(sleep(1000))
			.then(onConfirm)
			.catch((e) => {
				const errCode = e.response?.data?.error?.message
				alert(`Ocorreu um erro ao salvar: ${e.toString()}`)
			})
			.finally(() => setSaving(false))

	}

	const viewForm = useMemo(() => [
		// {
		// 	id:'titleCookies',
		// 	caption:'Alteração de status',
		// 	type:'title',
		// },
		{
			id:'id',
			type:'hidden',
		},
		{
			id:'uid',
			caption:'Identificação do Aparelho',
			type:'text',
			readOnly:true,
			row:true,
			col:6
		},
		...searchType ==='public'
			? [
				{
					id:'publicDocument',
					caption:'Número do documento (público)',
					type:'cpfcnpj',
					readOnly:true,
					row:true,
					col:6
				}
			]
			: [
				{
					id:'document',
					caption:'Documento / E-mail',
					type: form.state.model.document?.indexOf('@')>1 ? 'text' : 'cpfcnpj',
					readOnly:true,
					row:true,
					col:6
				}
			],
		{
			id:'remoteAddress',
			caption:'Endereço IP',
			type:'text',
			readOnly:true,
			row:true,
			col:6
		},
		{
			id:'channel.name',
			caption:'Canal',
			type:'text',
			readOnly:true,
			col:6
		},
		{
			id:'status',
			caption:'Status',
			type:'select2',
			options:cookiesConsentStatusOptions,
			required:true,
			noEmptyOption:true,
			row:true,
			readOnly,
			col:6,
			// onChange : (values, setFieldState) => {
			// 	const status=values.status
			// 	setFieldState('attachment', 'visible', status==='revoked')
			// }
		},
		{
			id:'createdAt',
			caption:'Dia/Horário',
			type:'text',
			readOnly:true,
			col:6,
		},
		...searchType ==='public' ? [] : [{
			id:'attachment',
			caption:'Arquivo anexo da solicitação de alteração de status',
			type:'upload',
			required:true,
			accept:acceptUploadTypeImageAndPdf,
			readOnly:false,
			col:6
		}],
	], [searchType, form.state.model])

    return (
		<ModalForm
			data={viewData}
			isVisible={!!viewData}
			fields={viewForm}
			saving={saving}
			loading={loading}
			modalStyle={{
				maxWidth : '50%',
				// maxHeight : '50vh',
			}}
			tabs={[
				{id:'history', caption:'Histórico',
					content:function A() { return <CookiesHistory cookiesConsentId={viewData?.id}/>}
				}
			]}
			onCancel={onCancel}
			onConfirm={handleSave}
			mainTabCaption="Informações"
		/>
    )
}

export default CookiesView;
