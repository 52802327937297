import React, {useEffect, useMemo, useState} from "react"

import {
    Col,
    Row,
} from "reactstrap"

import { useOvermind } from '../../../../overmind'
import SweetAlert from "react-bootstrap-sweetalert";

import LoadingSection from "../../../../components/Common/LoadingSection/LoadingSection";

import FormWizard from "../../../../components/Common/CustomForm/FormWizard";
import CustomForm from "../../CustomForm/Form";
import ButtonSecondary from "../../Button/ButtonSecondary";
import ButtonPrimary from "../../Button/ButtonPrimary";
import TabDefault from "../../Tabs/TabDefault";

const _ = require('lodash')

function ModalForm({
    	formId = 'ModalForm',
		data,
		isVisible,
		loading,
		title='',
		cancelBtnCaption="Cancelar",
		confirmBtnCaption="Salvar",
		confirmMessage,
		topContent,
		haveCancelBtn=true,
		saving=false,
		haveConfirmBtn=true,
		modalStyle = {},
		fields,
		tabs,
		closeOnClickOutside = true,
		onConfirm = () => {},
		onCancel = () => {},
		mainTabCaption='Informações',
		...props
}) {

    const {
        state   : {
            [formId]:{model, formValidationResult, fieldState, formValidatedCount, uploadedFiles}
        },
        actions : {
			submitForm, setModelValueByPath, setFormModel, resetForm, setFormValidationResult, setFieldState, incFormValidatedCount
        }
    } = useOvermind('forms')

	const [saved, setSaved] = useState(false)
	const [loadingInfo, setLoadingInfo] = useState(false)
	const [error, setError] = useState(false)
	const [activeTab, setActiveTab] = useState('__main');
	//const [data, setData] = useState(null)

	const mergedTabs = useMemo(() => [
		{id:'__main', caption:mainTabCaption, mainContent:true},
		...tabs || []
	], [tabs])

    useEffect(() => {
    	if (!data)
    		return

    	setError(false)
    	setSaved(false)

		setLoadingInfo(true)
		// setFormModel({formId, model:{...data}, resetUploads:false})
		setFormModel({formId, model:{...data}, resetUploads:false})
		// const uFiles =  _.cloneDeep(uploadedFiles);
		// console.log(formId)
    	// console.log(uFiles)
		incFormValidatedCount({formId})
		setTimeout(() => setLoadingInfo(false), 1)
    }, [data])

	const handleConfirm = async (e) => {
    	if (confirmMessage && !confirm(confirmMessage))
    		return

		const values = _.cloneDeep(model)
		onConfirm(values)
	}

	const statusScreens = useMemo(() => [
		{
			id:formId,
			fields : fields,
		},
	], [fields]);

	// useEffect(() => {
	// 	incFormValidatedCount({formId})
	// }, [fields])

	const handleCancel = () => {
    	onCancel()
	}

	const handleInputValueChange = () => {

	}

	if (!isVisible && !loading) return null
	if (loadingInfo)
		return null

    return (
        <>
			<SweetAlert
				title={title ?
					<h7 className="text-primary font-weight-700 font-family-title font-size-rem3 text-align-left">
						{title}
					</h7> : undefined
				}

				// warning={!error && !saving}

				style={{
					overflow:'auto',
					// marginTop:'0px',
					width:'80%',
					maxHeight:'100%',
					// minHeight:'300px',
					justifyContent:'flex-start',
					paddingBottom:0,
					...modalStyle
				}}
				openAnim={{ name: 'opacityIn', duration: 500 }}
				//closeAnim={{ name: 'slideOut', duration: 500 }}

				success={!error && saved}
				error={error}
				showCancel={false}
				showConfirm={false}
				allowEscape={true}
				cancelBtnText={cancelBtnCaption}
				confirmBtnText={confirmBtnCaption}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				// onConfirm={handleConfirm}
				onCancel={handleCancel}
				closeOnClickOutside={closeOnClickOutside}

			>
				{error ? (
						<>
						{error}
						<br/>
						<Row>
							<Col md={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
								<ButtonSecondary
									autoFocus
									onClick={handleCancel}
									className="p-2 m-3"
									caption="Fechar"
								/>
							</Col>
						</Row>
					</>
				) : (
					<LoadingSection loading={loading} error={error} loadingClassName="pb-3">
						{topContent}

						<Row className={"mb-0 "}>
							<Col md={12} className="p-0 m-0">
								<TabDefault
									tabs={mergedTabs}
									activeTab={activeTab}
									onChange={tabId => setActiveTab(tabId)}
									hasContent={true}
									containerClassName="pt-4"
									mainContent={<>
										<CustomForm
											className="needs-validation"
											formId={formId}
											validateOnLoad={true}
											onSubmit={handleConfirm}
											screens={statusScreens}
										>
											<Row className="pr-4 ps-4">
												<Col md={12} className="pt-2 ">
													<Row className="mb-0">
														<Col md={12} className="w-100 d-block align-items-start">
															<FormWizard
																validationInRealTime={true}
																screenId={formId}
																screens={statusScreens}
																formId={formId}
																OnInputValueChange={handleInputValueChange}
																tabId={formId}
															/>
														</Col>
													</Row>
													<Row className="position-sticky bottom-0 m-0 p-0 bg-white " style={{zIndex:999}}>
														<Col md={12} className="d-flex align-items-center justify-content-center ">
															{haveCancelBtn &&
															<ButtonSecondary
																autoFocus
																onClick={handleCancel}
																outlined={true}
																className="p-2 me-1 m-3"
																caption={cancelBtnCaption}
															/>
															}
															{haveConfirmBtn &&
															<ButtonPrimary
																loading={saving}
																// disabled={!!formValidationResult?.first || saving}
																className="p-2 ms-1 m-3"
																caption={confirmBtnCaption}
																type="submit"
															/>
															}

														</Col>
													</Row>
												</Col>
											</Row>
										</CustomForm>

									</>}
								/>
							</Col>
						</Row>

					</LoadingSection>
				)}

			</SweetAlert>

        </>
    )
}

export default ModalForm;
