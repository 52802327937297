//export const environment = "production"
//export const environment = "localhost"
//export const environment = "homolog"
export const environment = process?.env?.REACT_APP_ENVIRONMENT || 'localhost'

// const fiducUrls = {
// 	production : 'https://api2.fiduc.com.br/fiduc',
// 	production2 : 'https://api2.fiduc.com.br/fiduc',
// 	homolog : 'https://api2-hml.fiduc.com.br/fiduc',
// 	localhost : 'http://localhost:3333/fiduc',
// }

export const isRNWebView = window.isRNWebView || false

export const FRONT_END_SOURCE_KEY = '5XLA4WVSkDHcyRwx'

export const fiducUrl = environment === 'localhost'
	? `http://${isRNWebView ? "10.0.2.2" : window.location.hostname}:4444/`
	: process?.env?.REACT_APP_FIDUC_URL

export const acceptUploadTypeImage  = "image/png, image/jpeg, image/jpg, image/bmp"
export const acceptUploadTypeImageAndPdf  = "image/png, image/jpeg, image/jpg, image/bmp, application/pdf"
export const acceptAllFiles  = "*/*"

export const defaultFiducAcceptFiles = acceptUploadTypeImageAndPdf


//#2A2433,#304C5D,#26787B,#41A581,#8CCE75,#EFEE69

export const defaultGraphColors = [
	"#cfe0e8",
	"#f2e394",
	"#ddeedd",
	"#d96459",
	"#87bdd8",
	"#b0aac0",

	"#f0efef",
	"#f2ae72",
	"#b7d7e8",
	"#588c7e",
	"#c2d4dd",
	"#daebe8",

	"#35978f",
	"#fee08b",
	"#e14b31",
	"#d9ef8b",
	"#1984c5", "#22a7f0", "#48b5c4", "#76c68f", "#a6d75b", "#c9e52f", "#d0ee11", "#d0f400",

]


console.log('environment', {environment, fiducUrl})


