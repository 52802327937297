import React, {useEffect, useState} from "react"

import { useOvermind } from "../../../overmind";

import {dateIsoToBr} from "../../../helpers/utils";
import LoadingSection from "../../../components/Common/LoadingSection/LoadingSection";
import DefaultTable from "../../../components/Common/Tables/DefaultTable/DefaultTable";
import {
	cookiesConsentActionMapped,
	cookiesConsentStatusMapped,
} from "../../../constants/formLists";

function CookiesHistory({
	cookiesConsentId,
	...props
}) {

	const cookies = useOvermind('cookies')

	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)
	const [history, setHistory] = useState(null)

	useEffect(() => {
		setLoading(true)
		cookies.actions
			.listHistory(cookiesConsentId)
			//.then(sleep(2000))
			.then(data => setHistory(data))
			.catch(e => setError('Não foi possível carregar as informações'))
			.finally(() => setLoading(false))
	}, [])

	return (<>

		<LoadingSection
			loading={loading}
			error={error}
			noData={history?.length===0 ? "Não há histórico registrado." : null}
		>
			<DefaultTable
				className="pt-0 mt-0"
				columns={[
					{id:'createdAt', caption:'Data', formatter:(row) => dateIsoToBr(row.createdAt, null, 'DD/MM/YYYY [às] HH:mm:ss')},
					{id:'action', caption:'Ação', formatter:(row) => cookiesConsentActionMapped[row.action]?.caption || row.action},
					{id:'status', caption:'Status', formatter:(row) => cookiesConsentStatusMapped[row.status]?.caption || row.status},
					{id:'author', caption:'Autor', formatter:(row) => row.user?.name || <strong>Cliente</strong>},
					{id:'IP', caption:'IP', formatter:(row) => row.remoteAddress},
				]}
				data={history}
			/>
		</LoadingSection>
	</>)

}

export default CookiesHistory;
