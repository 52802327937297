import React, {useEffect, useMemo, useState} from "react"
import { useOvermind } from '../../../overmind'
import {UserCan} from "../../../helpers/api/auth";
import {statusOptions} from "../../../constants/formLists";
import ModalForm from "../../../components/Common/Modals/ModalForm";

function ChannelsView(props) {

    const {
		viewData,
		onConfirm = () => {},
		onCancel = () => {},
	} = props

	const readOnly = !UserCan('channels.edit')
	const channels = useOvermind('channels')
	const [saving, setSaving] = useState(false)

	const handleSave = async (data) => {

    	setSaving(true)
    	channels.actions.save(data)
			// .then(sleep(1000))
			.then(onConfirm)
			.catch((e) => {
				const errCode = e.response?.data?.error?.message
				alert(`Ocorreu um erro ao salvar: ${e.toString()}`)
			})
			.finally(() => setSaving(false))

	}

	const viewForm = useMemo(() => [
		// {
		// 	id:'titleChannels',
		// 	caption:'Configurações do Canal',
		// 	type:'title',
		// },
		{
			id:'id',
			type:'hidden',
		},
		{
			id:'nameId',
			caption:'Identificador do canal',
			info:'fefwef',
			type:'text',
			required:!Boolean(viewData?.id),
			row:true,
			readOnly:Boolean(viewData?.id),
			col:12
		},
		{
			id:'name',
			caption:'Nome do Canal',
			type:'text',
			required:true,
			row:true,
			readOnly,
			col:12
		},
		{
			id:'domain',
			caption:'Domínio',
			type:'text',
			required:true,
			row:true,
			readOnly,
			col:12
		},
		{
			id:'status',
			caption:'Status',
			type:'select2',
			options:statusOptions,
			required:true,
			row:true,
			readOnly,
			col:12
		},
	], [viewData?.id])

    return (
		<ModalForm
			data={viewData}
			isVisible={!!viewData}
			fields={viewForm}
			saving={saving}
			modalStyle={{
				maxWidth : '50%',
			}}
			onCancel={onCancel}
			onConfirm={handleSave}
			mainTabCaption="Configurações do Canal"
		/>
    )
}

export default ChannelsView;
