import React, {useState, useEffect} from "react"

import FieldText from "./../Inputs/Text";
import FieldCpf from "./../Inputs/Cpf";
import FieldCnpj from "./../Inputs/Cnpj";
import FieldCpfCnpj from "./../Inputs/CpfCnpj";
import FieldPhone from "./../Inputs/Phone";
import FieldEmail from "./../Inputs/Email";
import FieldDate from "./../Inputs/Date";
import FieldCep from "./../Inputs/Cep";
import FieldNumber from "./../Inputs/Number";
import FieldMoney from "./../Inputs/Money";
import FieldCheckGroup from "./../Inputs/CheckGroup";
import FieldRadioGroup from "./../Inputs/RadioGroup";
import FieldSelect from "./../Inputs/Select";
import FormTitleDefault from "./../SubTitles/TitleDefault";
import FieldUpload from "./../Inputs/Upload";
import FieldPassword from "./../Inputs/Password";

import * as Validations from "./../Utils/Validations";
import FieldTextArea from "../Inputs/TextArea";
import FieldTime from "../Inputs/Time";
import FieldSelect2 from "../Inputs/Select2";
import FieldSelect2Multi from "../Inputs/Select2Multi";
import FieldDateRange from "../Inputs/DateRange";
import FieldHidden from "../Inputs/Hidden";
import {isEmptyMoney} from "./../Utils/Validations";
import FieldPercent from "../Inputs/Percent";

//Registered field types
export const registeredFieldTypes = {
    title           : {component:FormTitleDefault, floatingLabels:false, noLabels:true},
    hidden          : {component:FieldHidden, defaultValidator:Validations.isValidValue, floatingLabels:false},
    text            : {component:FieldText, defaultValidator:Validations.isValidValue, floatingLabels:true, defaultIcon:'fas fa-keyboard'},
    textarea        : {component:FieldTextArea, defaultValidator:Validations.isValidValue, floatingLabels:true},
    password        : {component:FieldPassword, defaultValidator:Validations.isValidPassword, floatingLabels:true, defaultIcon:'fas fa-eye-slash', forceIconVisible:true},
    cpf             : {component:FieldCpf, defaultValidator:Validations.isValidCpf, floatingLabels:true, defaultIcon:'fas fa-keyboard'},
    cnpj            : {component:FieldCnpj, defaultValidator:Validations.isValidCnpj, floatingLabels:true, defaultIcon:'fas fa-keyboard'},
    cpfcnpj         : {component:FieldCpfCnpj, defaultValidator:Validations.isValidCpfCnpj, floatingLabels:true, defaultIcon:'fas fa-keyboard'},
    phone           : {component:FieldPhone, defaultValidator:Validations.isValidPhoneNumber, floatingLabels:true, defaultIcon:'fas fa-phone'},
    email           : {component:FieldEmail, defaultValidator:Validations.isValidEmail, floatingLabels:true, defaultIcon:'fas fa-envelope'},
    date            : {component:FieldDate, defaultValidator:Validations.isValidDate, floatingLabels:true, defaultIcon:'fas fa-calendar'},
    daterange       : {component:FieldDateRange, defaultValidator:Validations.isValidDateRange, floatingLabels:false},
    time            : {component:FieldTime, defaultValidator:Validations.isValidTime, floatingLabels:false},
    cep             : {component:FieldCep, defaultValidator:Validations.isValidCep, floatingLabels:true, defaultIcon:'fas fa-calendar'},
    number          : {component:FieldNumber, defaultValidator:Validations.isValidNumber, floatingLabels:true, defaultIcon:'fas fa-keyboard'},
    money           : {component:FieldMoney, defaultValidator:Validations.isValidMoney, floatingLabels:true, defaultIcon:'fas fa-donate', isEmptyFunc:isEmptyMoney},
    percent         : {component:FieldPercent, defaultValidator:Validations.isValidPercent, floatingLabels:true, defaultIcon:'mdi mdi-percent'},
    checkgroup      : {component:FieldCheckGroup, defaultValidator:Validations.isValidOptions, floatingLabels:false},
    radiogroup      : {component:FieldRadioGroup, defaultValidator:Validations.isValidOption, floatingLabels:false},
    select          : {component:FieldSelect, defaultValidator:Validations.isValidValue, floatingLabels:false},

    select2         : {component:FieldSelect2, defaultValidator:Validations.isValidOption, floatingLabels:true},
    select2multi    : {component:FieldSelect2Multi, defaultValidator:Validations.isValidOptions, floatingLabels:true},

    upload          : {component:FieldUpload, defaultValidator:Validations.isValidUpload, floatingLabels:false},
}


export const validateField = (field, model, fieldState, hasSubmitted, validateOnload) => {

	if (!field.type)
		return true

	let valid=true
	const validator = field.validation || registeredFieldTypes[field.type].defaultValidator

	const visible = !(fieldState[field.id]?.visible!==undefined && !fieldState[field.id]?.visible)
	const touched = fieldState[field.id]?.touched
	if (visible && validator) {
		if (field.requiredMethod)
			field.required = field.requiredMethod(model)
		// alert(field.id + ' ' + touched)
		//console.log(field.requiredMethod)

		const value = _.get(model, field.id) || field.defaultValue
		if (hasSubmitted || touched || validateOnload)
			valid =  validator(value, field, model)
		else
			valid = true
		// console.log('validation', {id:field.id, value, valid, hasSubmitted, touched})
		//valid = validator(value, field, model)
		return valid
	} else {
		return true
	}
}

export const validateFormScreens = (screenList, model, fieldState, hasSubmitted, validateOnload) => {

	const result = {}
	let first=null

	for (const screen of screenList) {

		for (const field of screen.fields) {
			if (!field.type) {
				console.log('no field type', field)
				continue
			}

			const valid = validateField(field, model, fieldState, hasSubmitted, validateOnload)

			if (valid!==true) {
				if (!result[screen.id])
					result[screen.id] = {}

				const fieldResult = {field, msg:valid}
				result[screen.id][field.id] = fieldResult;
				if (!first)
					first = fieldResult
				//field.validate()
			}

		}
	}

	return {first, errors:result}


}

export const validateFieldStates = (screenList, model, setFieldStateFunc, setInputValue) => {

	for (const screen of screenList) {

		for (const field of screen.fields) {

            if (field.onChange) {
                field.onChange(model, setFieldStateFunc, setInputValue)
            }

		}
	}

}

export const createFormFields = (formId, fields) => {
	return [
		{
			id:formId,
			fields : fields,
		},
	]
}
