import React from "react"
import { Route, Redirect } from "react-router-dom"
import { AuthInfo } from "../helpers/api/auth"

const Authmiddleware = (
	{
		component: Component,
		layout: Layout,
		isAuthProtected,
		...rest
	}) => (
	<Route
		{...rest}
		render={props => {
			if (isAuthProtected && !AuthInfo()) {
				return (
					<Redirect
						to={{ pathname: "/login", state: { from: props.location } }}
					/>
				)
			}

			return (
				<Layout>
					<Component {...props} />
				</Layout>
			)
		}}
	/>
)


export default Authmiddleware;
