import React, {useRef, useState, useEffect, createRef, useMemo, useCallback} from "react"
import {
	Col,
	Row,
	FormGroup,
	Label, Spinner
} from "reactstrap"

// import { useDelayUnmount } from "./Utils/Animation"
import { useOvermind } from '../../../overmind'
import { registeredFieldTypes, validateField } from "./Utils/InputTypes";

import debounce from 'lodash.debounce';
import {isEmptyFieldValue} from "./Utils/Validations";
import {autoSelectInputValue, autoSelectInputValueStart} from "./Utils/DomProp";

function triggerMouseEvent (node, eventType) {
    var clickEvent = document.createEvent ('MouseEvents');
    clickEvent.initEvent (eventType, true, true);
    node.dispatchEvent (clickEvent);
}
function Field({
	marginBottomClass='mb-3',
	...props
}) {
    let fieldRef = createRef()

    const FieldComponent = registeredFieldTypes[props.type] || registeredFieldTypes['text']
    //const FieldComponent = registeredFieldTypes['text']

    const {formId, validationInRealTime} = props

    const {
        state   : {
            [formId]:{Form, model, currentScreen, totalScreens, fieldState, readOnly, formValidationResult }
        },
        actions : {
            setFieldState, incFormValidatedCount, setFormModel, setCurrentScreen, getModelValueByPath, updateModelValueValues, setModelValueByPath
        }
    } = useOvermind('forms')

	const modelValue = getModelValueByPath({formId, path:props.id})
    const iconClass = fieldState[props.id]?.iconClass || props.icon || FieldComponent.defaultIcon
    const forceIconVisible = fieldState[props.id]?.forceIconVisible || props.forceIconVisible || FieldComponent.forceIconVisible
    // const inputFocusType = FieldComponent.inputFocusType
    const fieldCaption = fieldState[props.id]?.caption || props.caption
    const isEmptyFieldValueFunc = FieldComponent.isEmptyFunc || isEmptyFieldValue
    // const listOptions = fieldState[props.id]?.options || props.options
    // const listOptions = fieldState[props.id]?.options || props.options

    //const [focused, setFocused] = useState(false);
    const [labelClassName, setLabelClassName] = useState(
    	isEmptyFieldValueFunc(modelValue, props)
			? 'form-field-floating-label-blur'
			: 'form-field-floating-label-focus'
	);
    const [currentFieldError, setCurrentFieldError] = useState('');
    const [isFocused, setIsFocused] = useState(false);
	const [clickIconTrigger, setClickIconTrigger] = useState(null);

	// console.log('listOptions', listOptions)

	// const [listOptions, setListOptions] = useState(props.options);
	// useEffect(() => {
	// 	if (fieldState[props.id]?.options) {
	// 		setListOptions(fieldState[props.id]?.options)
	// 	}
	// }, fieldState[props.id]?.options)

    //Animation properties
    // const [fieldAnimationInitialized, setFieldAnimationInitialized] = useState(false);
    // const animationDuration = fieldAnimationInitialized ? 360 : 0
    // const animationDuration = 360
    // const [isMounted, setIsMounted] = useState(true);
    // const shouldRenderChild = useDelayUnmount(isMounted, animationDuration);
    // const mountedStyle = { position:'unset', animation: `opacityIn ${animationDuration}ms linear` };
    // const unmountedStyle = { position:'relative', animation: `opacityOut ${animationDuration+10}ms linear` };

    // can be used with css, but needs to set the same animationDuration above
    // const mountedClassName = 'form-field-animations-in';
    // const unmountedClassName = 'form-field-animations-out';


	//const modelValue =_.get(model, props.id)


	const handleChangeIntent = async (value) => {
		//alert(value)
        const form = Form?.FormCtrl

        if (form) {
			// const modelValueByPath =_.get(model, props.id)
			// if (modelValueByPath!=value) {
			// 	await setModelValueByPath({formId, path:props.id, value:value})
			// 	//incFormValidatedCount({formId})
			// }
			const values = form.getValues()
			if (props.onChangeIntent)
				props.onChangeIntent(values, setFieldStateFunc, setInputValue)

			//incFormValidatedCount({formId})
        }
	};

	// const debouncedChangeIntentEventHandler = useMemo(
	// 	() => debounce(handleChangeIntent, props.changeIntentInterval || 300)
	// , []);
	const debouncedChangeIntentEventHandler =
		debounce((value) => handleChangeIntent(value), props.changeIntentInterval || 618)

  	useEffect(() => {
		//console.log('changed', {id:props.id, fieldValue})
		//console.log('aaaaaa')
        setLabelClassName(!isEmptyFieldValueFunc(modelValue, props)
			? 'form-field-floating-label-focus'
			: 'form-field-floating-label-blur'
		);
	}, [modelValue])

    const getInputValue = (e) => {
        const form = Form?.FormCtrl
    	if (props.type=='checkgroup' || props.type=='select2' || props.type=='select2multi') {
            const values = form?.getValues()
			return _.get(values, props.id)
		} else {
    		return e.target.value
		}
	}

	const setInputValue = async (fieldNameOrListValues, value=null) => {

		const values = typeof fieldNameOrListValues == 'string'
			? {[fieldNameOrListValues]:value}
			: fieldNameOrListValues

		updateModelValueValues({formId, values})

        const form = Form?.FormCtrl
        if (form) {
			const inputs = form.getInputs()
			//console.log('inputs', inputs)
			for (const fieldName in values) {
				const input = inputs[`ref-select2-${fieldName}`] || inputs[fieldName]
				//console.log('input', input)
				//input.setValue(value)
				//form.setInputValue()
				//if (input && input.value !== undefined)
				console.log('input', input)
				try {
					input.value = values[fieldName]
				} catch(e) {
					console.log('could not set input value for ', input?.type)
				}
				//alert(input.value)

				//const event = new Event('change');
				//input.updater.enqueueForceUpdate()
				//setLabelClassName(value ? 'form-field-floating-label-blur' : 'form-field-floating-label-focus');
			}
		}
		incFormValidatedCount({formId})

    	//console.log('setInputValue', {fieldName, value})
    	//setModelValueByPath({formId, path:fieldName, value:value})
		//updateModelValueValues({formId, values:values})

        //await handleChange(fieldName, value)
	}

    const handleOnFocus = (e) => {
        setLabelClassName('form-field-floating-label-focus');
		setIsFocused(true)
        //setFocused(true)
		if (!isEmptyFieldValueFunc(e.target?.value, props)) {
		// 	autoSelectInputValueStart(e)
		// } else {
			autoSelectInputValue(e)
		}
    }

    const handleOnBlur = async (e) => {
        //if (!e.target.value)
        const form = Form?.FormCtrl

        if (form) {
            //const values = form.getValues()
            //const values = model
            //const valueByPath =_.get(values, e.target.name)


			const value = getInputValue(e)
            const modelValueByPath =_.get(model, props.id)
			// console.log('handleOnBlur', {
			// 	target : e?.target
			// })

			//form.setInputValue(e.target.name, 'wefwe')

            // console.log('valueByPath handleOnBlur', {path:props.id, modelValueByPath, value})
			//alert(props.type)
			if (modelValueByPath!=value && !['select2', 'select2multi'].includes(props.type)) {
				//console.log('handleBlur CHANGE', {id:props.id, value, type:props.type, valueType:typeof value})
				await setModelValueByPath({formId, path:props.id, value:value})
				//setModelValueByPath({formId, path:props.id, value:value})
				incFormValidatedCount({formId})
			}
            if (props.onBlur) {
	            //const values = form.getValues()
                props.onBlur(model, setFieldStateFunc, setInputValue)
            }
			 if (props.OnInputValueChange)
			 	props.OnInputValueChange({formId, path:props.id, value:value})

			//console.log(model)
            //const values = model
            //console.log(props.onChange)
            // if (props.onChange) {
            //     props.onChange(values, setFieldStateFunc, form)
            // }
            if (isEmptyFieldValueFunc(value, props)) {
                setLabelClassName('form-field-floating-label-blur');
				//console.log(model)
            }
        }
		setIsFocused(false)
        //setFocused(false)
    }

    const setFieldStateFunc = async (fieldName, key, value) => {
        await setFieldState({formId, fieldName, key, value})
    }

    const handleChange = async (id, value, update=false, updateModelValue=false) => {
        const form = Form?.FormCtrl
		//console.log('handleChange', {id, value, update})
		if (validationInRealTime)
			update=true

        if (1 || form) {
        	 //console.log('handleChange', {id, value, type:props.type})
            if (props.legacyValidation || update || updateModelValue) { //needed for browser autofill to work
	            const modelValueByPath =_.get(model, props.id)
				if (modelValueByPath!=value || updateModelValue) {
					// console.log(`setModelValueByPath ${formId}.${id}`, value)
					// if (['select2', 'select2multi', 'money'].includes(props.type))
						await setModelValueByPath({formId, path:props.id, value:value})
					// alert('set ' + props.id + ' is touched')
					if (!isEmptyFieldValueFunc(value, props))
						await setFieldStateFunc(props.id, 'touched', true)
					// console.log(`isEmpty ${props.id} ${isEmptyFieldValueFunc(value)}`)
					//incFormValidatedCount({formId})
				}
            	if (!props.legacyValidation && update) {
            		setTimeout(() => {
	            		incFormValidatedCount({formId})
					}, 1)
            	}
			}

            if (props.onChange && form) {
	            //const values = form.getValues()
	            const values = _.merge(_.cloneDeep(model), form.getValues());
                props.onChange(values, setFieldStateFunc, setInputValue)
            }

			//if (update) incFormValidatedCount({formId})

            //const valueByPath = getModelValueByPath({formId, path:props.id})
            //const valueByPath =_.get(values, props.id)
            //console.log('valueByPath handleChange', {path:props.id, valueByPath})

			// console.log('value', value)
            if (isEmptyFieldValueFunc(value, props)) {
			// if (value || value=='__/__/____') {
                setLabelClassName('form-field-floating-label-focus');
            }

            debouncedChangeIntentEventHandler(value)


        }
    }

    //fire onchange/initial events on first render and initialize animation
    // useEffect(() => {
	//
	// 	const valueByPath =_.get(model, props.id)
	// 	handleChange(props.id, valueByPath, false)
	//
	// 	// if (valueByPath) {
	// 	// 	setLabelClassName('form-field-floating-label-focus');
	// 	// }
	//
	// 	// if (props.onChange) {
	// 	// 	props.onChange(values, setFieldStateFunc, form)
	// 	// }
    //     //handleChange()
	//
    //     //setFieldAnimationInitialized(true)
    //     /*
    //     //Skip first render animations
    //     setTimeout(() => {
    //         setFieldAnimationInitialized(true)
    //     }, 1000)*/
	//
    // }, [])

    //Set animation visibility for this field, fired from other fields validations
    // if (fieldState[props.id]?.visible!==undefined && !fieldState[props.id]?.visible) {
    //     if (isMounted) setIsMounted(false)
    //     //return null
    // } else {
    //     if (!isMounted) setIsMounted(true)
    // }

    const {col, row, ...rest} = props

    // if (!shouldRenderChild) {
    //     rest.disabled = true
    // }

    if (props.readOnly || (readOnly && props.globalReadOnly!==false)) {
        rest.disabled = true
        //rest.required = undefined
    }

    // const getDisplayAndAnimationStyle = () => {
    // 	return {
	// 		// ...isMounted ? mountedStyle : unmountedStyle,
	// 		...(shouldRenderChild) ? {display: isMounted ? 'unset' : 'none'} : {display:'none'},
	// 		paddingRight:0,
	// 	}
	// }

    //const displayAndAnimationStyle = useMemo(() => getDisplayAndAnimationStyle(), [isMounted, shouldRenderChild])
    // const displayAndAnimationStyle = getDisplayAndAnimationStyle()
	const handleFormGroupMouseEvent =  useCallback((evt) => {

		try {
			if (['select2', 'select2-multi'].includes(props.type)) {

				const element = document.querySelector(`[data-id="form-control-${props.id}"] .select2-selection__control`)
				if (element && evt.target.contains(element)) {
					triggerMouseEvent(element, 'mousedown')
					triggerMouseEvent(element, 'mouseup')
				}
			}
		} catch(e) {
			console.log(e)
		}
	}, [])

	//Validation Effect
	useEffect(() => {

		if (!formValidationResult?.errors || !formValidationResult?.errors[props.tabId] || !props.tabId) {
			setCurrentFieldError(null)
			return
		}


		const currentTabErrors = formValidationResult?.errors[props.tabId]
		const currentScreenError = currentTabErrors && currentTabErrors[props.screenId]
		const fieldError = (currentScreenError && currentScreenError[props.id]) || null
		setCurrentFieldError(fieldError)

	}, [formValidationResult])


	const getFieldRequired = (required) => {
    	if (typeof required != 'function') {
    		return required
		}

        const form = Form?.FormCtrl
        if (form) {
			const values = form.getValues()
			return props.required(values)
		}
		return false
	}

	const SubComponent = (props) => {
    	const {componentProps} = props
    	const data = componentProps.dataPath
			? getModelValueByPath({formId, path:componentProps.dataPath})
			: null
		//console.log('data', data)
		return <componentProps.component  data={data} />
    }

	const fieldPropsRequired =
		props.legacyValidation
			? props.required
			: undefined

	const fieldPropsValidation =
		props.legacyValidation
			? {val:() => validateField(props, model, fieldState, Form?.FormCtrl?.getValues())}
			: undefined

	let onlyId = ''
	// let onlyId = ' > '+props.id
	if (0 && props.id) {
		onlyId = props.id.split('.')
		onlyId = ' (' + onlyId[onlyId.length-1] + ')'
	}

	const helperClassCountFirst = props.isFirstColumn ? "is-first" : ''
	const helperClassCountLast = props.isLastColumn ? "is-last" : ''

    if (fieldState[props.id]?.visible!==undefined && !fieldState[props.id]?.visible) {
    	return null
	}

    // console.log('currentFieldError', currentFieldError)
	const FComponent = FieldComponent.component

    return (
        <React.Fragment >
            {row && <Row className={`${props.type==='hidden' && "d-none"} `} />}
			{props.tComponent && <SubComponent componentProps={props.tComponent}/>}
            <Col
				className={`
					debug2 field-column 
					${helperClassCountFirst} 
					${helperClassCountLast} 
					flex-fill
					${props.type==='hidden' && "d-none"}
				`}
                md={col || 12}
                sm={props.sm}
                xs={props.xs}
                xl={props.xl}
				lg={props.lg}
                // style={displayAndAnimationStyle}
            >
                <FormGroup
				   	id={`form-control-${props.id}`}
					data-id={`form-control-${props.id}`}
					onClick={handleFormGroupMouseEvent}
					className={`
                		form-field ${marginBottomClass}
                		form-field-type-${props.type}
                		${fieldCaption ? "" : "form-field-empty-caption"}
                		${isEmptyFieldValueFunc(modelValue, props) && !isFocused ? "form-field-empty-value" : ""}
                		${rest.disabled && "form-field-disabled"} 
                		flex-column d-flex
				   `}
				>
                    {!FieldComponent.noLabels && (
                        <div className={'floating-label-container '} >
                            <label
								disabled={rest.disabled}
                                className={
                                	FieldComponent.floatingLabels? labelClassName : 'form-field-floating-label-focus'
                                }
                                htmlFor={'field-'+props.id}
								// style={{color:currentFieldError ? "var(--bs-danger)"  : undefined}}
							>
								{/*v={'"'}{modelValue}{'"'} -*/}
								{fieldCaption}{onlyId}
                            </label>
                        </div>
                    )}

					{(!props.customError && currentFieldError && !['radiogroup', 'checkgroup', 'upload', 'textarea'].includes(props.type))
						? <div className={'floating-label-container '} >
							<span className="form-field-error-message">{currentFieldError?.msg && `* ${currentFieldError?.msg}`}</span>
						</div>
						: null
					}
					{(!props.customError && currentFieldError && ['radiogroup', 'checkgroup'].includes(props.type))
						? <div className="debug align-self-center justify-content-center text-danger font-size-rem-2 position-absolute">
								{currentFieldError?.msg && `* ${currentFieldError?.msg}`}
							</div>
						: null
					}

					{props.rComponent && <SubComponent componentProps={props.rComponent}/>}
					{(iconClass && (!currentFieldError || forceIconVisible)) &&
						<div
							className="form-field icon p-2"
							onClick={() => setClickIconTrigger(!clickIconTrigger)}
						>
						<i className={iconClass + ' debug3 p-2 p-2'} />
					</div>
					}

					<FComponent
						key={props.id}
                        inputRef={r => (fieldRef = r)}
                        name={props.id}
						className={currentFieldError ? 'is-invalid' : undefined}
                        type="text"

						// options={props.options}
						// listId={props.listId}
						// caption={props.caption}
						// file_caption={props.file_caption}
						// formId={formId}
						{...rest}

                        id={'field-'+props.id}

						setFieldState={setFieldStateFunc}
						clickIconTrigger={clickIconTrigger}

                        onChange={handleChange}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
						customFormatter={props.customFormatter}
						fieldError={currentFieldError}
						isFocused={isFocused}
						isEmptyFieldValue={isEmptyFieldValueFunc(modelValue, props)}

						required={fieldPropsRequired}
						validation={fieldPropsValidation}
						modelValue={modelValue}
                    />

					{(!props.customError && currentFieldError && ['textarea'].includes(props.type))
						? <div className="debug align-self-center justify-content-center text-danger font-size-rem-2 ">
								{currentFieldError?.msg && `* ${currentFieldError?.msg}`}
							</div>
						: null
					}

{/*					<div*/}
{/*						style={{*/}
{/*//							backgroundColor:'blue',*/}
{/*							border:'solid 0px red',*/}
{/*							position: 'relative',*/}
{/*							float:'right',*/}
{/*							top:'2rem',*/}
{/*							right:'0.19em',*/}
{/*							width:'2.5em',*/}
{/*							height:'1.5em',*/}
{/*							display:'flex',*/}
{/*							justifyContent:'center',*/}
{/*							alignItems:'center',*/}
{/*							padding:'0em'*/}



{/*						}}*/}
{/*					>*/}
{/*						<Spinner*/}
{/*							style={{*/}
{/*								padding:'1rem',*/}
{/*								width: "1em",*/}
{/*								height: "1em",*/}
{/*								opacity:0.3,*/}
{/*							}}*/}
{/*							color="primary"*/}

{/*						/>*/}
{/*					</div>*/}


                </FormGroup>
            </Col>
        </React.Fragment>
    )
}

export default Field;
