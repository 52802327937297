import React, {useEffect, useMemo, useState} from "react"
import { useOvermind } from '../../../overmind'
import {UserCan} from "../../../helpers/api/auth";
import ModalForm from "../../../components/Common/Modals/ModalForm";

function ProfilesView(props) {

    const {
    	apiOptions,
		viewData,
		onConfirm = () => {},
		onCancel = () => {},
	} = props

	const readOnly = !UserCan('profiles.edit')
	const [saving, setSaving] = useState(false)
	const [apis, setApis] = useState([])

	const profiles = useOvermind('profiles')

    useEffect(() => {
		profiles.actions
			.loadApis()
			.then(data => {
				setApis(data)
			})
			.catch(e => {
				alert('Erro ao carregas as apis disponíveis')
			})

	}, [])

	const handleSave = async (data) => {

		const apiPermissions = []

		for (const api of apis) {
			const apiSelected = data.apis && data.apis[api.id]
			if (apiSelected) {
				apiPermissions.push({
					apiId: api.id,
					consult: apiSelected.indexOf('consult')>-1,
					edit: apiSelected.indexOf('edit')>-1,
					exclude: apiSelected.indexOf('exclude')>-1,
					include: apiSelected.indexOf('include')>-1,
					approve: apiSelected.indexOf('approve')>-1,
				})
			}
		}
		// console.log('data', data)
		delete data.apis
		data.apiPermissions = apiPermissions

    	setSaving(true)
    	profiles.actions.save(data)
			// .then(sleep(1000))
			.then(onConfirm)
			.catch((e) => {
				const errCode = e.response?.data?.error?.message
				alert(`Ocorreu um erro ao salvar: ${e.toString()}`)
			})
			.finally(() => setSaving(false))

	}

	const viewForm = useMemo(() => {
		if (!apis) return []
		const fields = [
			// {
			// 	id:'titleProfiles',
			// 	caption:'Permissões do perfil',
			// 	type:'title',
			// },
			{
				id:'id',
				type:'hidden',
			},
			{
				id:'name',
				caption:'Nome do perfil',
				type:'text',
				required:true,
				row:true,
				readOnly,
				col:12
			},
		]

		for (const api of apis) {
			fields.push({
				id:`apis.${api.id}`,
				caption:api.nameTranslated,
				type    : 'checkgroup',
				options:apiOptions,
				required:false,
				vertical:true,
				readOnly,
				// row:true,
				col:4,
			})
		}
		return fields
	}, [apis])

	//const operation = investmentOperationMapped[investmentData?.operation || -1] ? investmentOperationMapped[investmentData.operation] : {caption:'Operação Inválida'}

    return (
		<ModalForm
			data={viewData}
			isVisible={!!viewData}
			fields={viewForm}
			saving={saving}
			modalStyle={{
				maxWidth : '50%',
			}}
			onCancel={onCancel}
			onConfirm={handleSave}
			mainTabCaption="Permissões do perfil"
		/>
    )
}

export default ProfilesView;
