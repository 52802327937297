import React from "react"
import ReactDOM from "react-dom"
import {GuestUniqueID, ResetGuestUniqueID, SetGuestUniqueID} from "../../helpers/api/auth";
const insertionQ = require("insertion-query")

if (!window.Fiduc)
	window.Fiduc = {}
if (!window.Fiduc.plugins)
	window.Fiduc.plugins = {}

class FiducPlugin {

	constructor(pluginName) {
		this.name = pluginName
		this.setConfig(window.Fiduc)
	}

	setConfig(config) {
		this.config = config
	}

	addConfig(config) {
		this.config = {...this.config, ...config}
	}

	deleteConfig(name) {
		delete this.config[name]
	}

	resetGuestUniqueID() {
		ResetGuestUniqueID()
		this.deleteConfig('userDocument')
	}

	getGuestUniqueID() {
		return GuestUniqueID()
	}

	setGuestUniqueID(uid) {
		return SetGuestUniqueID(uid)
	}

	on(name, func, replace=false) {
		if (!window.Fiduc[name] || replace)
			window.Fiduc[name] = []
		window.Fiduc[name].push(func)
	}

	off(name, func) {
		if (!window.Fiduc[name])
			return
		const idx = window.Fiduc[name].indexOf(func)
		if (idx>-1)
			window.Fiduc[name].splice(idx, 1)

	}

	clearEvents(names) {
		for (const name of names)
			window.Fiduc[name] = []
	}

	fireEvent(name, params, runOnce=false) {
		const events = window.Fiduc[name]
		if (!events?.length) return
		for (const evt of events) {
			if (typeof evt === 'function') {
				try {
					console.log('EVENT:', name, evt, params)
					evt(params)
				} catch(error) {
					console.log(`FiducPlugin event Error Evt:${name}`, evt)
				}
			}
		}
		if (runOnce) {
			window.Fiduc[name] = []
		}

	}

}

export const RegisterPlugin = (pluginName, pluginClass) => {
	const instance = new pluginClass(pluginName)
	window.Fiduc.plugins[pluginName] = {instance}
	return instance
}

const RenderElement = (pluginName, Index, element) => {
	const attrs = element.getAttributeNames().reduce((acc, name) => {
		return {...acc, [name]: element.getAttribute(name)};
	}, {});
	console.log('RenderElement', {pluginName, attrs})

	ReactDOM.render(<Index pluginParams={attrs} />, element)
}

export const RenderPlugin = (pluginName, Index) => {

	window.addEventListener("load",()=> {
		insertionQ(pluginName).every(function(element){
			RenderElement(pluginName, Index, element)
		});

		const elements = window.document.getElementsByTagName(pluginName)
		for (const element of elements) {
			RenderElement(pluginName, Index, element)
		}
	   	//document.body.classList.add('page-ready');
	})
}


export default FiducPlugin
