import api, {sleep} from "../../helpers/api/api";
import {reloadRemoteList} from "../../constants/remoteLists";


export const loadById = async ( { state, actions }, id ) => {

	return api
		.get(`profiles/${id}`)
		.then(async res => {
			return {
				...res.data,
				active : res.data.active===1 ? "1" : "0"
			}
		})
}

export const save = async ( { state, actions }, data ) => {

	const request = parseInt(data.id)
		? api.put(`profiles/${data.id}`, data)
		: api.post(`profiles/`, data)

	return await request
		.then(async res => {
			await reloadRemoteList('UserProfile')
			return res.data
		})
}

export const deleteById = async ( { state, actions }, id ) => {

	return api.delete(`profiles/${id}`)
		.then(async res => {
			await reloadRemoteList('UserProfile')
			return res.data
		})
}

export const loadApis = async ( { state, actions } ) => {

	return api.get(`apis`)
		.then(async res => {
			return res.data
		})
}

