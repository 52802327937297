import lStorage from "../../helpers/api/lStorage";
import moment from "moment"
moment.locale('pt-BR')

const defaultPeriod = [
	moment().startOf('year').format('YYYY-MM-DD'),
	//moment().startOf('year').format('YYYY-MM-DD'),
	moment().format('YYYY-MM-DD')
]

export const forms = {
    pageTitle : 'Test',

    ProfilesSearch : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    ChannelsSearch : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    CookiesSearch : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    UsersSearch : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    MyAccount : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {},
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    ModalForm : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    ModalPasswordReset : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    Dashboard : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			period : null,
			edit : {
				status : '',
				canceledReason : '',
				pendingReason : '',
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

    },

    loginForm : {

        //Fprm default/initial values
        model : {
            document : lStorage.get('last-login-document') || '',
            password : '',
            // document : '32006302809',
            // password : 'Atlantida@13',
        },

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,


        //Uploaded files

        uploadedFiles : {

        }

    },


}
