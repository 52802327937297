import React from "react"
import {Col, Row, Spinner} from "reactstrap";

function LoadingSection( {children, loadingClassName, ...props} ) {

	if (props.loading)
		return (
			<Row key={'loading-section'} className={loadingClassName}>
				<Col md={12} className="text-center p-4">
					<Spinner
						style={{ width: "1em", height: "1em"  }}
						color="primary"
					/>
				</Col>
			</Row>
		)

	if (props.error)
		return (
			<Row>
				<Col md={12} className="text-center">
					{props.error}
				</Col>
			</Row>
		)

	if (props.noData)
		return (
			<Row>
				<Col md={12} className="text-center">
					{props.noData}
				</Col>
			</Row>
		)

    return <React.Fragment key={'loading-section'}>{children}</React.Fragment>
}

export default LoadingSection;
