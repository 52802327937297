import React, {useState} from "react"

import FieldText from "./Text";

export const validate = (value) => {
    return value && value.toString().trim() ? true : false
}

function FieldHidden(props) {
	return <FieldText {...props} type={"hidden"}/>
}

export default FieldHidden;
