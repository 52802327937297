import { useState, useEffect } from "react";
import lStorage from "./lStorage";


function getStorageValue(key, defaultValue, type='object') {
	// getting stored value
	let saved = lStorage.get(key)
	// if (type==='integer')
	// 	saved = parseInt(type)
	// alert(saved)
	return saved==null ? defaultValue : saved
}

export const useLocalStorage = (key, defaultValue) => {
	const [value, setValue] = useState(() => {
		return getStorageValue(key, defaultValue);
	});

	useEffect(() => {
		// storing input name
		lStorage.set(key, value)
	}, [key, value]);

	return [value, setValue];
};
