import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, {useMemo, useState} from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

import CustomForm from "../../components/Common/CustomForm/Form";



import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
// import { AvForm, AvField } from "availity-reactstrap-validation"

//import logo from "assets/images/logo.svg"
import logo from "assets/images/logos/icon-light.svg"

import { useOvermind } from "../../overmind"
import { validatePasswordStrong } from "../../components/Common/CustomForm/Utils/Validations"

// import api from "../../helpers/api/api";
import Button from "../../components/Common/Button/Button";
import ModalForm from "../../components/Common/Modals/ModalForm";
import {AuthInfo} from "../../helpers/api/auth";
import lStorage from "../../helpers/api/lStorage";
import {createFormFields} from "../../components/Common/CustomForm/Utils/InputTypes";
import FormWizard from "../../components/Common/CustomForm/FormWizard";
import ButtonPrimary from "../../components/Common/Button/ButtonPrimary";

const validateConfirmPassword = (value, props, values) => {

	const {
		password=null,
		confirm_password=null,
	} = values || {}

	// alert(confirm_password)
	if (password !== confirm_password)
		return false

	return (validatePasswordStrong(password, props, values) && validatePasswordStrong(confirm_password, props, values))
}

const Login = ({history, props}) => {

	const [error, setError] = useState('')
	const [resetPasswordData, setResetPasswordData] = useState(null)

	const formId = "loginForm"

	const {
		state   : {
			[formId] : { model }
		},
		actions : {
			submitForm
		}
	} = useOvermind('forms')

	const login = useOvermind('login')
	const users = useOvermind('users')
	//console.log('model', model)

	const loginFields = useMemo(() => createFormFields(formId, [
		{
			id:'document',
			caption:'CPF / CNPJ',
			type:'cpfcnpj',
			required:true,
			row:true,
			col:12,
			icon:"fas fa-keyboard",
		},
		{
			id:'password',
			caption:'Senha',
			type:'password',
			required:true,
			col:12,
		},
	]), []);

	const resetPasswordFields = useMemo(() => [
		{
			id : 'title',
			type : 'title',
			caption : 'Definição de nova senha',
			subtitle : <><br/>
				Para poder continuar acessando a aplicação, digite uma nova senha e confirme.<br/><br/>
				<small>
					* Pelo menos um caractere especial, uma letra maiúscula, uma letra minúscula e mínimo de 8 caracteres!
				</small>
			</>,
			row : true,
			col : 12,
		},
		{
			id : 'password',
			type : 'password',
			caption : 'Nova senha',
			required : true,
			validation:validateConfirmPassword,
			row : true,
			col : 12,
		},
		{
			id : 'confirm_password',
			type : 'password',
			caption : 'Repita a senha',
			required : true,
			validation:validateConfirmPassword,
			row : true,
			col : 12,
		},
	], [])
	// handleValidSubmit
	const handleSubmit = (values) => {
		//console.log('handleSubmit', values)
		//console.log('submit values', values)
		//return
		login.actions
			.login(values)
			.then(res => {
				lStorage.set('last-login-document', values.document)
				if (res.data?.userStatus=="change password") {
					setResetPasswordData({})
				} else {
					history.push('/')
				}
				// console.log('res LOGIN', res)


			})
			.catch(e => {
				//setError(e.toString())
				setError('Não foi possível continuar, CPF ou Senha estão incorretos.')
				throw e
			})
	}

	return (
		<React.Fragment>
			<MetaTags>
				<title>Fiduc Serviços</title>
			</MetaTags>
			<ModalForm
				formId={'ModalPasswordReset'}
				data={resetPasswordData}
				isVisible={!!resetPasswordData}
				fields={resetPasswordFields}
				modalStyle={{
					maxWidth : '50%',
				}}
				onCancel={() => {
					setResetPasswordData(null)
				}}
				onConfirm={(values) => {
					console.log('values', values)
					users.actions
						.changePassword({document:AuthInfo().document, newPassword:values.password})
						.then(() => {

							alert('Sua senha foi atualizada!')
							setResetPasswordData(null)
							history.push('/')
						})
						.catch(e => {
							const errCode = e.response?.data?.[0]?.code
							if (errCode=='ERR084') {
								alert("A nova senha não pode ser igual à senha antiga.")
							} else {
								alert("Ocoreu um erro ao tentar resetar a senha!")
							}
						})
				}}
			/>
			<div className="home-btn d-none d-sm-block">
				<Link to="/" className="text-dark">
					<i className="fas fa-home h2" />
				</Link>
			</div>
			<div className="account-pages my-5 pt-sm-5">
				<Container>
					<Row className="justify-content-center">
						<Col md={8} lg={6} xl={5}>
							<Card >
								<div style={{background:'var(--bs-primary) ', borderRadius:'0.25rem', margin:'1em'}}>
									<Row>
										<Col md={12}>
											<div
												style={{
													display:'flex',
													alignItems:'center',
													justifyContent:'center',
												}}
											>
											<div className="avatar-md profile-user-wid mb-0 d-flex justify-content-center align-items-center p-2">
												<img src={logo} alt="" height="50" />
											</div>
											</div>
											<div className="text-primary pe-4 ps-4 pb-1 text-center">
												<h5 className="text-white text">Fiduc Serviços</h5>
											</div>
										</Col>
									</Row>
								</div>
								<CardBody className="pt-2">
									<div className="p-2 pb-2">
										{error ? <Alert color="danger">{error}</Alert> : null}
										<CustomForm
											className="needs-validation"
											autoComplete={false}
											screens={loginFields}
											onSubmit={handleSubmit}
											formId={formId}
											autoFocusField={model.document ? 'password' : 'document'}
										>
											<FormWizard
												validationInRealTime={true}
												screenId={formId}
												screens={loginFields}
												formId={formId}
												// OnInputValueChange={handleInputValueChange}
												tabId={formId}
											/>

											<div className="mt-4 d-flex justify-content-center">
												<ButtonPrimary
													className="p-2"
													fillWidth={true}
													type="submit"
													loading={login.state.authenticating}
													caption="Entrar"
													// onClick={() => {
													// 	submitForm({formId, action:'submit'})
													// }}
												/>
											</div>



										</CustomForm>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	)
}

export default withRouter(Login)

Login.propTypes = {
	history: PropTypes.object,
}
