import React, {useState} from "react"

import { AvField } from "availity-reactstrap-validation"
import {isValidEmail} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";

function FieldEmail(props) {

    const [value, setValue] = useState('')

    const handleChange = (e) => {
    	setValue(e.target.value)
        if (props.onChange)
            props.onChange(props.name, e.target.value)
    }

    // const {caption, type, inputRef, formId, screenId, validation, ...rest} = props
	const rest = getValidDomProperties(props)
    return (
        <AvField
            ref={props.inputRef || undefined}
            className="form-control"
            type="text"
            {...rest}
            errorMessage={props.errorMessage || `${props.caption} é obrigatório`}
            autoComplete="disabled"
			inputMode="email"
            onChange={handleChange}
			required={props.required}
            // validate={{val:() => isValidEmail(value, props) }}
            validate={props.validation}
        />
    )
}

export default FieldEmail;
