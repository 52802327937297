import React, {useEffect, useMemo, useState} from "react"

const FiducPlugin = require("../../../components/Plugins/FiducPlugin")

// const Cookies = require("plugins/cookies")
import CookiesVisual from "plugins/cookies-visual/CookiesVisual"
import Cookies from "plugins/cookies"
import {useOvermind} from "../../../overmind";

function TestPluginCookiesVisual(props) {

	const login = useOvermind('login')
	const [consent, setConsent] = useState(null)

	const getConsentStatus = async () => {
		return Cookies
			.getConsentStatus()
			.then(data => {
				setConsent(data)
				console.log(data)
			})
	}

	const setConsentStatus = (status) => {
		Cookies
			.setConsentStatus(status)
			.then(getConsentStatus)
	}

	useEffect(() => {


		getConsentStatus()

		// alert(Cookies.config.token)
		// console.log(Cookies)

		// console.log(AuthInfo())
	}, [])


    return (
		<cookiesvisual>
			<CookiesVisual
				pluginParams={{
					token:'66bc0dda-bcd9-4c3d-9337-3c82083a9fb5',
				}}
			/>
		</cookiesvisual>
	)
}

export default TestPluginCookiesVisual;
