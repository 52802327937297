import React from "react";

import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/images/logos/icon-light.svg";
import logoLightPng from "../../assets/images/logos/logo-light.svg";
import logoLightSvg from "../../assets/images/logos/icon-light.svg";
import logoDark from "../../assets/images/logo-dark.png";

const Sidebar = props => {

	return (
		<React.Fragment>
			<div className="vertical-menu">
				<div className="navbar-brand-box">
					<Link to="/" className="logo logo-dark">
					<span className="logo-sm">
					  <img src={logo} alt="" height="22" />
					</span>
					<span className="logo-lg">
					  <img src={logoDark} alt="" height="17" />
					</span>
					</Link>

					<Link to="/" className="logo logo-light">
					<span className="logo-sm justify-content-center align-items-center pt-3" >
					  <img src={logoLightSvg} alt="" height="41" />
					</span>
					<span className="logo-lg justify-content-center align-items-center pt-3">
		              <img src={logoLightPng} alt="" height="28" />
        		    </span>
					</Link>
				</div>
				<div data-simplebar className="h-100">
					<SidebarContent />
				</div>
				<div className="sidebar-background"></div>
			</div>
		</React.Fragment>
	)
}

export default Sidebar
