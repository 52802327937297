import React, {useState} from "react"

import InputMask from "react-input-mask";
import { Input } from 'reactstrap';
import { AvField } from "availity-reactstrap-validation"

import {getValidDomProperties} from "../Utils/DomProp";

function FieldTime(props) {


    const [value, setValue] = useState('')

    const handleChange = (e) => {
    	//console.log('handleChange', e.target.value)
        setValue(e.target.value)
        if (props.onChange)
            props.onChange(props.name, e.target.value)
    }

    // const {caption, type, inputRef, formId, screenId, validation, ...rest} = props
	const rest = getValidDomProperties(props)
    return (
        <AvField
            ref={props.inputRef || undefined}
            className="form-control"
            mask="99:99"
            maskChar="_"
			alwaysShowMask={true}
            tag={[Input, InputMask]}
            {...rest}
            autoComplete="disabled"
            type="text"
			inputMode="decimal"
            onChange={handleChange}
			required={props.required}
            // validate={{val:() => isValidDate(value, props) }}
            //validate={props.validation}
            validate={props.validation}
        />
    )
}

export default FieldTime;
