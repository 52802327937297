import { app } from './app/state';
import { forms } from './forms/state';
import { login } from './login/state';
import { users } from './users/state';
import { profiles } from './profiles/state';
import { channels } from './channels/state';
import { cookies } from './cookies/state';

export const state = {
    app,
    forms,
    login,
	users,
	profiles,
	channels,
	cookies,
};
