import React, {useEffect, useState} from "react"

import { AvField, AvFeedback, AvInput, AvGroup  } from "availity-reactstrap-validation"
import {Col, Input, Label, Row, CustomInput, Spinner} from 'reactstrap';
import {useOvermind, useOvermindForm} from "../../../../overmind";
import Dropzone from "react-dropzone";
import { Buffer } from 'buffer';

import pdfIcon from "../../../../assets/images/forms/pdf-icon.png"
import {getValidDomProperties} from "../Utils/DomProp";
import api from "../../../../helpers/api/api";
// import ButtonLink from "../../Button/ButtonLink";
import ButtonIcon from "../../Button/ButtonIcon";


// const b64toBlob = (b64Data, contentType='', sliceSize=999999999999) => {
// 	const byteCharacters = atob(b64Data);
// 	const byteArrays = [];
//
// 	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
// 		const slice = byteCharacters.slice(offset, offset + sliceSize);
//
// 		const byteNumbers = new Array(slice.length);
// 		for (let i = 0; i < slice.length; i++) {
// 			byteNumbers[i] = slice.charCodeAt(i);
// 		}
//
// 		const byteArray = new Uint8Array(byteNumbers);
// 		byteArrays.push(byteArray);
// 	}
//
// 	const blob = new Blob(byteArrays, {type: contentType});
// 	return blob;
// }

export const formatBytes = (bytes, decimals = 2) => {
	if (bytes===undefined || isNaN(bytes)) return undefined
    if (bytes===0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export const downloadImageBase64 = async (url) => {
	return api
		.get(url, {
			responseType: 'arraybuffer',
		})
		.then((response) => {
			const contentType = response.headers['content-type']
			//alert(contentType)
			//const dataBuffer = Buffer.from(response.data, 'binary')

			let dataUri, dataBuffer= Buffer.from(response.data, 'binary')
			// alert(contentType)
			if (contentType=='application/pdf') {
				dataUri = pdfIcon
			} else if (contentType && response.data) {
				dataUri = `data:${contentType};base64,${dataBuffer.toString('base64')}`
			}

			return {dataUri, dataBuffer:response?.data, size:dataBuffer?.length, contentType}
		})
		.catch(() => {
			console.log('!!!!!!!!!!!!!!!!!!!!');
			console.log('error converting image', url);
			console.log('!!!!!!!!!!!!!!!!!!!!');
		});
};

const getBase64 = (file, callback, errprCallback) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        callback(reader.result)
        //console.log('loaded: ', reader.result);
    };
    reader.onerror = function (error) {
        if (errprCallback)
            errprCallback(error)
    };
}

export const IS_UPLOADED_FILE = '[File Upload]'

function FieldUpload(props) {
    //let inputRef = React.createRef();
	//let localDataBuffer

	const {formId} =  props
	const rest = getValidDomProperties(props)

    const [loading, setLoading] = useState(false)

    const {
        state   : {
            [formId]:{uploadedFiles, readOnly }
        },
        actions : {
            incFormValidatedCount, setUploadedFiles, setModelValueByPath
        }
    } = useOvermind('forms')
    //console.log('uploadedFiles', uploadedFiles)

	//if (parseBase64)

    //const selectedFile = uploadedFiles[props.name]
    const [selectedFile, setSelectedFile] = useState(null)
    const [value, setValue] = useState('')
	// const [downloading, setDownloading] = useState(false)
	const [localDataBuffer, setLocalDataBuffer] = useState(null)
	useEffect(() => {
		// console.log('uploaded file', uploadedFiles[props.name])
		setSelectedFile(uploadedFiles[props.name])
	}, [uploadedFiles, uploadedFiles[props.name]])

	useEffect(() => {
		if (!selectedFile || !selectedFile?.remoteUrl)
			return
		const remoteUrl = selectedFile?.remoteUrl
		// const saveRemoteUrl = _.cloneDeep(selectedFile?.remoteUrl)

		setLoading(true)
		downloadImageBase64(remoteUrl)
			.then(({dataUri, dataBuffer, size, contentType}) => {

				//if (saveFormResetCount!=formResetCount)
				//alert(`${saveRemoteUrl} - ${selectedFile.remoteUrl}`)
				//console.log(res?.data.substr(0, 100))
				//const contentType = res.headers['content-type']
				setLocalDataBuffer(new Blob([dataBuffer], {type:contentType}))
				// alert(contentType)
				const fileResult = {
					name : selectedFile?.name,
					preview: dataUri,
					contentType: contentType,
					canDownload : true,
					formattedSize: formatBytes(size),
				}
				setUploadedFiles({formId, fieldName:props.name, fileResult})
			})
			.catch(err => {
				console.log(`Error downloading remoteImage:`, remoteUrl, err)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [selectedFile?.remoteUrl])

    //const selectedFile = getModelValueByPath({formId, path:props.name})
	const downloadFileClient = async () => {
		// setDownloading(true)
		if (!selectedFile)
			return

		//return
		//const blob = b64toBlob(selectedFile.objectUrl.split(',')[1], 'application/octet-stream')
		const a = document.createElement('a');
		//alert(res.data.length)
		//console.log('localDataBuffer', localDataBuffer)

		console.log('selectedFile', selectedFile)

		// if (!localDataBuffer) {
		// 	return
		// }

		const url = localDataBuffer ? window.URL.createObjectURL(localDataBuffer) : selectedFile.preview;

		if (!url)
			return;
		const fileName = selectedFile.name || props.caption
		a.href = url;
		a.download = fileName;
		document.body.append(a);
		a.click();
		a.remove();
		window.URL.revokeObjectURL(url);
	}

    function handleAcceptedFiles(files) {
        if (files.length==0) {
            alert('O arquivo escolhido não é válido')
            return
        }
        setLoading(true)
		console.log('handleAcceptedFiles')

        files.map(file => {
                // console.log('file', file)
               // const pdf = new Blob([data], {type: 'application/pdf'});
				const isPdf = file.type=='application/pdf'
				const objectUrl = URL.createObjectURL(file)
				//setLocalDataBuffer(objectUrl)
				setLocalDataBuffer(new Blob([file], {type:file.type}))

                let fileResult = {
                	changed : true,
                    name : file.name,
					objectUrl : objectUrl,
					contentType : file.type,
					canDownload : true,
					isPdf : file.type==='application/pdf',
                    preview : isPdf
						? pdfIcon
						: objectUrl,
                    formattedSize: formatBytes(file.size),
                }
                //setUploadedFiles({formId, fieldName:props.name, fileResult})
				//setModelValueByPath({formId, path:props.name, value:fileResult})

                //setLoading(false)
				// if (props.onChange)
				// 	props.onChange(props.name, fileResult)
                //Object.assign(file, fileResult)

                getBase64(file,
                    (base64String) => {
                        fileResult.base64String = base64String
                        //console.log('fileResult', fileResult)

                        setUploadedFiles({formId, fieldName:props.name, fileResult})
						setModelValueByPath({formId, path:props.name, value:IS_UPLOADED_FILE})
                        setLoading(false)
						handleChange(props.name, Math.random())
                    },
                    (error) => {
                        setUploadedFiles({formId, fieldName:props.name, base64String:''})
						setModelValueByPath({formId, path:props.name, value:''})
						handleChange(props.name, '')
                        setLoading(false)
                        console.log('getBase64 Error: ', error);
                    })
                //setUploadedFiles({formId, fieldName:props.name, base64String:reader.result})
            }
        )
        //console.log(files)
    }

	const DropZoneHasNoFile  = () => {
		return (
			<div className={`dz-text-muted dz-image-not-selected invalid-feedback-upload p-2 pb-0 pt-2`} style={{minHeight:'5.3rem', maxHeight:'5.3rem'}}>
				{loading ?
					<div>
						<Spinner
						  style={{ width: "0.5rem", height: "0.5rem", marginRight:"0.5em", marginTop: '1rem' }}
						  // type="grow"
						  color="danger"
						/>
						<br />
						<span style={{fontSize:'0.8rem'}}>carregando...</span>
					</div>
				:
					<div
						style={{
							fontSize:'1rem',
							paddingTop:'0.6rem',

							// color: props.fieldError ? 'var(--bs-danger)' : undefined,
						}}
					>
					{props.file_caption || `Enviar "${props.caption}"`}
					</div>
				}
				{!loading && <div className="mt-0"	>
					<label
						className='font-size-10 font-weight-normal'
						style={{
							color: props.fieldError ? 'var(--bs-danger)' : undefined,
							"--bs-text-opacity" : 1,
						}}
					>
					{`* clique para selecionar um arquivo`}
				</label></div>}
			</div>
		)
	}

	const DropZoneHasFile  = () => {
		return (
			<div
				style={{

					display:'flex',
					alignItems:'center',
					justifyContent:'center',
					//border:'solid 2px red',
				}}
			>
				<div className={'debug dz-image-preview '}
					 style={{
						flexGrow:1,
						 //border:'solid 1px purple',
						 display:'flex',
						 justifyContent:'center',
						 alignItems:'center',
					 }}
				>
					<img src={selectedFile.isPdf ? pdfIcon : selectedFile.preview} style={{height:'3.8em'}} />
					{/*<div >*/}
					{/*	<label className="dz-text-selected font-weight-semibold text-opacity-75 p-2 pt-4 overflow-hidden ">*/}
					{/*		{props.file_caption} <br />*/}
					{/*		<label className='dz-text-filesize'>{selectedFile.formattedSize ? `${selectedFile.formattedSize}` : ""}</label>*/}
					{/*	</label>*/}
					{/*</div>*/}
				</div>
			</div>
		)
	}

    const DropZoneInsideBox = () => {
        if (selectedFile && selectedFile?.preview) {
        	return <DropZoneHasFile />
        } else {
        	return <DropZoneHasNoFile />
        }
    }

	const onDrop = React.useCallback(acceptedFiles => {
		handleAcceptedFiles(acceptedFiles)
	}, []);

    const InputDropZone = () => {

		//console.log('InputDropZone', selectedFile)
		// console.log('RENDERED InputDropZone')

        return (
            <Dropzone
				// noDragEventsBubbling={true}
                onDrop={onDrop}
                accept={props.accept || undefined}
                multiple={false}
				disabled={props.disabled}


            >
                {({ getRootProps, getInputProps }) => (
                	<div
						className="debug1 dropzone"
						disabled={props.disabled}
						 style={{
							border:`1px dashed ${props.fieldError ? 'var(--bs-danger)' : '#f1efe9'}`,
							color:`1px dashed ${props.fieldError ? 'var(--bs-danger)' : '#f1efe9'}`,
						}}
					>
                    <div className={`debug1 w-100`}  style={{
                    	paddingTop:props.caption ? '1.75rem' : '.75rem'
					}}>
                        <div
                            className="dz-message needsclick dropzone-container"
                            {...getRootProps()}

                        >
                            <input {...getInputProps()}  />
                            <DropZoneInsideBox />
                        </div>
                    </div>
					{(!loading && selectedFile && !selectedFile?.deleted) && (
						<div className="bg-white d-flex flex-row debug justify-content-sm-center align-items-center py-2 ">
							<ButtonIcon
								loading={false}
								caption={selectedFile.isPdf ? `Excluir` : "Excluir"}
								color="var(--bs-fiduc-danger)"
								icon={"fas fa-trash"}
								reverse={false}
								disabled={readOnly}
								className="px-2"
								// disabled={currentScreen==0}
								onClick={evt => {
			                        setUploadedFiles({formId, fieldName:props.name, fileResult:{changed:true, deleted:true}})
									setModelValueByPath({formId, path:props.name, value:''})
									incFormValidatedCount({formId})
									//evt.preventDefault()
								}}
							/>
							<ButtonIcon
								loading={false}
								caption={"Download"}
								disabled={!selectedFile?.canDownload}
								className="px-2"
								color="var(--bs-fiduc-danger)"
								icon={"bx bxs-download"}
								// disabled={currentScreen==0}
								onClick={downloadFileClient}
							/>
						</div>
					)}
                	</div>
                )}
            </Dropzone>
        )

    }

    const handleChange = (name, value) => {
		setValue(value)
		incFormValidatedCount({formId})
        // if (props.onChange)
        //     props.onChange(props.name, e.target.value, true)
    }

    // console.log('rest', rest)

	// console.log('RENDERED InputDropZone')
    return (
        <>
            <AvField
	            ref={props.inputRef || undefined}
                name={props.name}
                validate={undefined}
                type="hidden"
				onChange={(e) => setValue(e.target.value)}
				value={value}
            />
            <InputDropZone
				key={props.id}
                {...rest}
                name={`ref-dropzone-${props.name}`}
			/>
            {/*<AvField*/}
            {/*    tag={[Input, InputDropZone]}*/}
            {/*    {...rest}*/}

			{/*	onChange={undefined}*/}
            {/*    name={`ref-dropzone-${props.name}`}*/}

			{/*	required={undefined}*/}
	        {/*    // validate={props.validation}*/}
            {/*/>*/}
        </>
    )
}

export default FieldUpload;
