import React from "react"
import Button from "./Button";
import AutoIcon from "../AutoIcon";

function ButtonPrimary (
	{
		caption='',
		fillWidth=false,
		outlined=false,
		small=true,
		icon,
		disabled,
		className,
		height,
		width,
		loading,
		color="primary",
		...props
	}
	) {
    return <Button
			className={`btn ${outlined ? `btn-outline-${color}` : `btn-${color}`} ${small ? "btn-sm" : ""}  ${fillWidth && "w-100"} ${className} d-flex justify-content-center align-items-center`}
			buttonStyle={{height, width}}
			loading={loading}
			disabled={disabled}
			{...props}
		>
			{(icon && !loading) &&
				<AutoIcon icon={icon} className={caption ? "me-1" : undefined}/>
			}
			{caption}
		</Button>

}

export default ButtonPrimary;
