import React, {Component, useEffect, useState} from "react"
import LoadingSection from "../../components/Common/LoadingSection/LoadingSection";

import moment from "moment"
import DefaultPage from "../../components/Common/DefaultPage/DefaultPage";
import CustomForm from "../../components/Common/CustomForm/Form";
import {useOvermind} from "../../overmind";
import DefaultPageHeader from "../../components/Common/DefaultPage/DefaultPageHeader";
moment.locale('pt-BR')

const Dashboard = (props) => {

	const formId = 'Dashboard'
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)

	const {
		state   : {
			[formId] : {Form, model}
		},
		actions : {
			// submitForm,
			setModelValueByPath,
			// setFormState, setFieldState, setFormModel, resetForm,
			// updateModelValueValues, incFormValidatedCount, incFormResetCount
		}
	} = useOvermind('forms')

	useEffect(() => {

		setLoading(false)
		setError('Dashboard não implementada')
	}, [])

	return (
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title="Dashboard"  />
			}
		>
				<CustomForm
					className="needs-validation "
					formId={formId}
					model={model}
					validateOnLoad={false}
				>

					<LoadingSection loading={loading} error={error}>

					</LoadingSection>
				</CustomForm>
		</DefaultPage>
	)

}



export default Dashboard;
