import React from "react"
import api from "../helpers/api/api"

const loadedOptions = {}

const remoteLists = {

	// ProfessionalOccupation : {
	// 	url : '/carrers',
	// 	dataId : 'id',
	// 	dataCaption : 'name',
	// },

	// Country : {
	// 	url : '/countries',
	// 	dataId : 'id',
	// 	dataCaption : 'name',
	// },

	UserProfile : {
		url : `profiles`,
		dataId : 'id',
		dataCaption : 'name',
	},


}

export const registerRemoteList = (listId, config) => {
	remoteLists[listId] = config
}

export const reloadRemoteList = async  (listId) => {
	delete loadedOptions[listId]
	await loadOptions(listId)
}

export const loadOptions = (listId, overmind) => {
	const listConfig = remoteLists[listId]

	// console.log('overmind', overmind)
	const {clients=null} = overmind || {}

	if (!listConfig) {
		console.log("List Id not registered: ", listId)
		return Promise.resolve([]);
	}

	if (loadedOptions[listId] && listConfig.cache!==false) {
		//console.log('FROM CACHE', listId)
		return Promise.resolve(loadedOptions[listId]);
	}

	const url = typeof listConfig.url === 'function'
		? listConfig.url({clients})
		: listConfig.url

	//console.log("LOADING", listId)
	return api
		.get(url)
		.then(async (response) => {

			const reducer = listConfig.reducer || function(map, obj) {
				map.push({id:obj[listConfig.dataId], caption:obj[listConfig.dataCaption]})
				return map;
			}

			loadedOptions[listId] = response.data.reduce(reducer, []);

			//console.log("LOADED", listId)
			return loadedOptions[listId]
		})
		.catch(async (error) => {
			console.log('Error loading list ', {listId, error})
			return []
		});
}

export const loadAllRemoteLists = async () => {
	console.log('Loading all remote lists...')
	const promises = []
	for (const listId in remoteLists)
		promises.push(loadOptions(listId))

	return Promise.all(promises)
}

