import React from "react"
import {isCnpj, isCpf} from "../../../../helpers/validation_helper";
import {parseMoney, parsePercent} from "../../../../helpers/parser_helper";
import {dateBrToISO, MoneyFormat, onlyNumbers} from "../../../../helpers/utils";
//import {parseMoney} from "../../../../helpers/parser_helper";
// import { registeredFieldTypes } from "./../Utils/InputTypes";

const _ = require('lodash')


export const isEmptyFieldValue = (fieldValue) => {
	//fieldValue = fieldValue.toString()
	return (
		!fieldValue
		|| fieldValue === '(  )      -    '
		|| fieldValue === '(  )     -    '
		|| fieldValue === '__/__/____'
		|| fieldValue === 'R$ 0'
		|| fieldValue === 'R$ 0,00'
		|| fieldValue === '   .   .   -         '
		|| fieldValue === '  .   .   /    -  '
	)

}

export const isEmptyMoney = (value, props) => {
	const parsedValue = value ? MoneyFormat(parseMoney(value), 'R$ ', props.maskOptions?.allowDecimal?2:0) : ""
	return isEmptyFieldValue(parsedValue)
}

export const validatePasswordStrong = (password, props) => {
    if (
      password === undefined ||
      password === null ||
      password === "" ||
      password.length < 8
    ) {
		return false;
    }
    // This regex checks if there is at least one special char in the string.
    const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (!specialCharsRegex.test(password)) {
		return false;
    }
    // This regex checks if there is at least one capital char in the string.
    const capitalCharRegex = /\w*[A-Z]\w*/;
    if (!capitalCharRegex.test(String(password))) {
    	return false;
    }
    // This regex checks if there is at least one numeric char in the string.
    const numericCharRegex = /\d+/;
    if (!numericCharRegex.test(String(password))) {
		return false;
    }
    return true;

}

export function isValidCep(value, props) {
	const pValue = value ? value : '';
	const isEmpty = (pValue=='_____-___') || !pValue
	if (props.required && isEmpty) {
		return 'Campo obrigatório'
	} else if (!props.required && isEmpty) {
 		return true
	}

	return pValue?.replace(/[^\d]+/g,'').length==8 ? true : props.errorMessage || 'CEP Inválido'
}

export const requiredIfPF = (values, form) => {
	if (values.client.welfare!='0')
		return false
	return !values.client.document || isCpfFormat(values.client.document) ? true : false
}

export const requiredIfNotCapable = (values, form) => {
	if (values.client.birthDate=='27/08/2010')
		return false

	return true
}

export const isCpfFormat = (value) => {
	const res = onlyNumbers(
			value?.replace(/[^\d]+/g,'').trim()
		)
		.toString()
		.length
	return res< 12
}

export const isValidCpfCnpj = (value, props) => {
	const pValue = value ? value : '';

	//const isEmpty = (pValue=='___.___.___.__') || (pValue=='__.___.___/____-__') || !pValue.trim()
	const isEmpty = isEmptyFieldValue(pValue)

	if (props.required && isEmpty) {
		return 'Campo obrigatório'
	} else if (!props.required && isEmpty) {
		return true
	}

	if (isCpfFormat(pValue)) {
		return isCpf(pValue) ? true : props.errorMessage || 'Informe um CPF válido'
	} else {
		return isCnpj(pValue) ? true : props.errorMessage || 'Informe um CNPJ válido'
	}
}

export const isValidCpf = (value, props) => {
	const pValue = value ? value : '';
	// const isEmpty = (pValue=='___.___.___.__') || (pValue=='__.___.___/____-__') || !pValue.trim()
	const isEmpty = isEmptyFieldValue(pValue)

	if (props.required && isEmpty) {
		return 'Campo obrigatório'
	} else if (!props.required && isEmpty) {
		return true
	}

	return isCpf(pValue) ? true : props.errorMessage || 'Informe um CPF válido'
}

export const isValidCnpj = (value, props) => {
	const pValue = value ? value : '';
	// const isEmpty = (pValue=='___.___.___.__') || (pValue=='__.___.___/____-__') || !pValue.trim()
	const isEmpty = isEmptyFieldValue(pValue)

	if (props.required && isEmpty) {
		return 'Campo obrigatório'
	} else if (!props.required && isEmpty) {
		return true
	}

	return isCnpj(pValue) ? true : props.errorMessage || 'Informe um CNPJ válido'
}

export const isValidDate = (value, props) => {
	//return true
	// console.log('isValidDate', value)
	const pValue = !isEmptyFieldValue(value) ? value.trim() : '';

	if (props?.required && !pValue)
		return "Campo obrigatório"

	// console.log('pValue', pValue)
	//
	if (!props?.required || dateBrToISO(pValue))
		return true
	else
		return "Data inválida"

}

export const isValidDateRange = (value, props) => {
	//return true
	//console.log('isValidDate', value)
	const pValue = typeof value=='object' ? value.length : 0;

	if (props.required && pValue<2)
		return "Campo obrigatório"

	if (!props.required)
		return true
	else
		return "Período inválido"

}

export const isValidTime = (value, props) => {
	//return true
	//console.log('isValidTime', value)
	const pValue = value ? value.trim() : '';

	if (props.required && !pValue)
		return "Campo obrigatório"

	const pValues = pValue.split(':')

	const hour = parseInt(pValues[0])
	const minute = parseInt(pValues[1])

	if (!pValues.length || value.indexOf('_')>0 || isNaN(minute) || isNaN(hour) )
		return "Horário inválido"

	if (hour>23)
		return "Hora não pode ser maior que 23"

	if (minute>59)
		return "Minutos não pode ser maior que 59"

	return true

}

export const isValidEmail = (value, props) => {
	const pValue = value ? value.trim() : '';
	const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	if (props.required && !pValue)
		return "Campo obrigatório"

	if (re.test(pValue))
		return true
	else
		return "E-mail inválido"

}

export const isValidMoney = (value, props) => {
	const parsedValue = parseMoney(value)

	if (!props.required) {
		return !isNaN(parsedValue)
			? true
			: 'Digite um valor válido'
	}

	return !isNaN(parsedValue) && parsedValue!=0
		? true
		: props.errorMessage || `Campo obrigatório`


}

export const isValidPercent = (value, props) => {
	const parsedValue = parsePercent(value)

	if (!props.required) {
		return !isNaN(parsedValue)
			? true
			: 'Digite um valor válido'
	}

	return !isNaN(parsedValue) && parsedValue!=0
		? true
		: props.errorMessage || `Campo obrigatório`


}

export const isValidPhoneNumber = (value, props) => {
	const parsedValue = value?.replace(/[^\d]+/g,'') || '';

	let validated
	if (!props.required && !parsedValue) {
		return true
	} else {
		validated = parsedValue?.length >= 2 && (parsedValue?.[2] === "9")
			? parsedValue.length==11
			: parsedValue.length==10
	}

	return validated ? true : `Digite um número de telefone válido`
}

export const isValidNumber = (value, props) => {
	const pValue = value ? value.trim() : '';
	//console.log('pValuenumber', pValue)
	if (value==='0') return true

	if (!props.required) {
		return !isNaN(pValue)
			? true
			: 'Digite um número válido'
	}


	if (props.minLength && pValue.length<props.minLength) {
		return `Mínimo de ${props.minLength} dígitos`
	}

	return pValue && !isNaN(pValue) && pValue!==0
		? true
		: `Campo obrigatório`

}

export const isValidValue = (value, props) => {

	//console.log({props, value})
	const pValue = value ? value?.toString().trim() : '';

	if (props.required) {
		return pValue
			? true
			: 'Campo obrigatório'
	}

	return true

}

export const isValidPassword = (value, props) => {

	//console.log({props, value})
	const pValue = value ? value?.toString().trim() : '';

	if (props.required) {
		return pValue.length>0
			? true
			: 'Campo obrigatório'
	}

	return true

}

export const isValidOption = (value, props) => {

	//console.log({props, value})
	const pValue = value ? value?.toString().trim() : '';

	if (props.required) {
		return pValue
			? true
			: 'Escolha uma opção'
	}

	return true

}

export const isValidOptions = (value, props) => {
	//console.log({props, value})
	const pValue = value ? value : [];

	if (props.required) {
		return pValue
			? pValue.length>0
			: 'Campo obrigatório'
	}

	return true

}

export const isValidUpload = (value, props) => {
	const pValue = value ? value?.toString().trim() : '';

	if (props.required) {
		return pValue
			? true
			: 'Escolha um arquivo'
	}

	return true

}

