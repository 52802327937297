export const app = {
	remoteListsLoaded : false,
	remoteListsLoading : false,
	searchCount : 0,
    searchParams : {
    	searchString : '',
		status : '',
		renewStatus : '',
	},

}
