import React, { useState, useEffect } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withRouter, Link, useHistory } from "react-router-dom"

// users
import {AuthInfo, Logout} from "../../../helpers/api/auth";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")
	const authInfo = AuthInfo()
	const history = useHistory()

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

	const handleMyAccount = () => {
	  history.push({
		  pathname:"/myAccount",
	  })
	}

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="d-none d-sm-inline-block ms-2 me-1 font-size-10 btn-link text-black">{authInfo.name}</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/*<DropdownItem tag="a" href="/profile">*/}
          {/*  {" "}*/}
          {/*  <i className="bx bx-user font-size-16 align-middle me-1"/>*/}
          {/*  {props.t("Profile")}{" "}*/}
          {/*</DropdownItem>*/}
          {/*<DropdownItem tag="a" href="/crypto-wallet">*/}
          {/*  <i className="bx bx-wallet font-size-16 align-middle me-1"/>*/}
          {/*  {props.t("My Wallet")}*/}
          {/*</DropdownItem>*/}
          {/*<DropdownItem tag="a" href="#">*/}
          {/*  <span className="badge bg-success float-end">11</span>*/}
          {/*  <i className="bx bx-wrench font-size-16 align-middle me-1"/>*/}
          {/*  {props.t("Settings")}*/}
          {/*</DropdownItem>*/}
          <Link
			  onClick={handleMyAccount}
			  to="/myAccount" className="dropdown-item d-flex align-items-center"
		  >
            <i className="fas fa-user-alt font-size-14 align-middle me-2 "/>
            <span>Dados cadastrais</span>
          </Link>
          <div className="dropdown-divider"/>
          <Link
			  onClick={Logout}
			  to="/logout" className="dropdown-item d-flex align-items-center "
		  >
            <i className="bx bx-power-off font-size-16 align-middle me-2 text-danger"/>
            <span>Encerrar seção...</span>
		  </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}


export default ProfileMenu
