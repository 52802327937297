
import {environment} from './configuration'
const prefixKey = environment==='production' ? "services-" : `${environment}-services-`

class lStorage {

	get(key) {
		let data = null, value;
		try {
			value = localStorage.getItem(prefixKey + key)

			if (value.toString().toLowerCase().trim()==='true' ) {
				data = true
			} else if (value.toString().toLowerCase()==='false' ) {
				data = false
			} else {
				data = value ? JSON.parse(value) : null;
			}
		} catch (e) {
			data = null
			console.log(`STORAGE GET ERROR: ${key} ${typeof value} = "${value}"`, e.message);
		}
		 // console.log(`lStorage.get(${key}):`, {data, type:typeof data});
		return data;
	}

	set(key, value) {
		try {
			if (typeof value === 'boolean')
				value = value ? 'true' : 'false'
			else
				value = value ? JSON.stringify(value) : ''
			// console.log('setStorage', {key, value})
			localStorage.setItem(prefixKey + key, value);
		} catch (e) {
			console.log('STORAGE SET ERROR: ', e.message);
		}
	}

}



export default new lStorage();
