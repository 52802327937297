import React, {useState} from "react"

import InputMask from "react-input-mask";
import { AvField } from "availity-reactstrap-validation"
import { Input } from 'reactstrap';
import { isCpf } from '../../../../helpers/validation_helper'
import {isValidCpfCnpj} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";

function FieldCpf(props) {

    const [value, setValue] = useState('')
    const handleChange = (e) => {
        setValue(e.target.value)
        if (props.onChange)
            props.onChange(props.name, e.target.value)
    }

    //const {caption, type, inputRef, formId, screenId, validation, ...rest} = props
	const rest = getValidDomProperties(props)
    return (
        <AvField
            ref={props.inputRef || undefined}
            className="form-control"
            mask="999.999.999.99"
            maskChar=""
            tag={[Input, InputMask]}
            {...rest}
            autoComplete="disabled"
            type="text"
			inputMode="decimal"
            onInput={handleChange}
			required={props.required}
            // validate={{val:() => isValidCpfCnpj(value, props) }}
            validate={props.validation}
			value={props.value}

        />
    )
}

export default FieldCpf;
