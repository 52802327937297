import React, {useEffect, useCallback} from "react"

// import "../../assets/scss/theme.scss"
// import "../../assets/scss/fiduc/fiduc.scss"

import "../../assets/scss/fiduc/plugins/cookies-visual.scss"
import Cookies from "plugins/cookies"

const CookiesVisual = (
	{
		pluginParams,
		...props
	}
) => {

	useEffect(() => {
		if (Cookies)
			Cookies.setConfig(pluginParams)

		console.log({pluginParams})
		const container = document.getElementById('policy-container')?.parentElement;
		if (container)
			(document.getElementById('root') || document.body).appendChild(container)
		getConsentStatus()

		return () => {
			try {
				document.getElementById('root').removeChild(container)
			} catch(e) {}
		}
	}, [])

	const show = useCallback(() => {
		const container = document.getElementById('policy-container')
		if (container) {
			container.classList.remove('hidden')
			container.classList.add('visible')
		}
	}, [])

	const hide = useCallback(() => {
		const container = document.getElementById('policy-container')
		if (container) {
			container.classList.remove('visible')
			container.classList.add('hidden')
		}
	}, [])

	const getConsentStatus = () => {
		if (!Cookies)
			return Promise.reject({})

		Cookies
			.getConsentStatus()
			.then(consent => {
				if (!consent?.status)
					show()
			})
	}

	const setConsentStatus = (status) => {
		if (!Cookies)
			return Promise.reject({})

		Cookies
			.setConsentStatus(status)
			.then(hide)
	}

	return (
		<div id='policy-container' className="policy-container hidden debug1">
			<div className="policy-content">
				<div className="left debug2">A <strong>
					Fiduc</strong> coleta cookies para otimizar o desempenho deste site e oferecer a você a melhor
					experiência de usuário. Ao clicar em "aceitar", você concorda com a nossa utilização de cookies.
					Para obter mais informações, leia a nossa{" "}
					<a className="policy-link"
					   href="https://fiduc.com.br/wp-content/uploads/2022/09/fiduc-fgf-2022-08-politica-de-privacidade.pdf"
					   target="_blank" rel="noreferrer">Política de Privacidade</a>.
				</div>
				<div className="right debug2">
					<div className="buttons-container">
						<a
							href="#"
							className="button primary button-accept"
							onClick={e => {setConsentStatus('accepted'); e.preventDefault()}}
						>
							<strong>Aceito</strong>
						</a>
						<a
							href="#"
							className="button secondary button-deny"
							onClick={e => {setConsentStatus('denied'); e.preventDefault() }}
						>
							<strong>Não aceito</strong>
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CookiesVisual
